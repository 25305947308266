import { FAQItem } from "../../../interfaces/models/FAQInterface";
import { LOGIN_LOGOFF } from "../Login/constants";
import { FAQ_ERROR, FAQ_LOADING, FAQ_SUCCESS } from "./constants";

export interface FaqState {
	loading: boolean;
	loaded: boolean;
	data?: FAQItem[];
	error?: string;
}

const initialState: FaqState = {
	loading: false,
	loaded: false,
};

type Action = {
	type: string;
	payload?: FAQItem[];
	error?: string;
};

export default function FaqReducer(
	state = initialState,
	action: Action
): FaqState {
	switch (action.type) {
		case FAQ_LOADING:
			return {
				...initialState,
				loading: true,
			};
		case FAQ_SUCCESS:
			return {
				...initialState,
				loading: false,
				loaded: true,
				data: action.payload,
			};
		case FAQ_ERROR:
			return {
				...initialState,
				loaded: true,
				error: action.error,
			};
		case LOGIN_LOGOFF:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
