/* eslint-disable react/display-name */
import React, { Dispatch, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import PoliticianDetailTemplate from "../components/template/PoliticianDetailTemplate/PoliticianDetailTemplate";
import { AuthToken } from "../interfaces/AuthInterface";
import { PoliticianDetailResponse } from "../interfaces/models/PoliticianDetail";
import { PoliticianDetailAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<PoliticianDetailResponse>();

const getPolitician = async (
  auth: AuthToken,
  id: number,
  dispatch: Dispatch<SearchAction<PoliticianDetailResponse>>
) => {
  dispatch({ type: "loading" });
  try {
    const result = await PoliticianDetailAPI(auth, id);
    dispatch({ type: "loaded", result });
  } catch (e) {
    dispatch({ type: "error", error: e });
  }
};

function PoliticianDetail() {
  const [{ loading, error, result }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const { id } = useParams<any>();
  const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);

  useEffect(() => {
    getPolitician(auth, id, dispatch);
  }, [id, auth]);

  return (
    <MainTemplate title="Consultar Políticos" withoutContainer>
      <LoadingError loading={loading} error={error} />
      {result && <PoliticianDetailTemplate politician={result} />}
    </MainTemplate>
  );
}

export default PoliticianDetail;
