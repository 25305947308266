import React, { useReducer, useState } from "react";
import MainTemplate from "../../../components/template/MainTemplate/MainTemplate";
import { Container } from "react-bootstrap";
import useConnectedSelector from "../../../utils/useConnectedSelector";
import FilterFormFormik from "../../filterFormFormik/FilterFormFormik";
import LoadingError from "../../../components/molecules/LoadingError/LoadingError";
import SearchReducer from "../../../components/reducers/SearchReducer";
import { sendFilterBidding } from "../../../services/bidding/biddingFiltersApi";
import BiddingFilterResult, {
  BiddingFilterResultResponse,
} from "./BiddingFilterResult";

const [initialState, reducer] = SearchReducer<BiddingFilterResultResponse>();

const BiddingFilter = () => {
  const [{ result, loading, error }, dispatch] = useReducer(
    reducer,
    initialState
    );
    
  const { biddingFilters, biddingFiltersLoading, auth } = useConnectedSelector(
     ({ BiddingFiltersReducer, LoginReducer }) => ({
      auth: LoginReducer.data,
      biddingFilters: BiddingFiltersReducer.data,
      biddingFiltersLoading: BiddingFiltersReducer.loading,
    })
  );
  
  const sendBiddingFilter = () => sendFilterBidding(auth, dispatch);

  const filters = useState(biddingFilters ? biddingFilters : []);
  return (
    <MainTemplate title="Lotes" withoutContainer>
      <Container fluid className="bg-white mt-4 pt-0 px-4 pb-4 rounded">
        <FilterFormFormik
          sendFilter={sendBiddingFilter}
          filters={filters[0]}
          loading={loading}
          filterLabel={"Filtrar Lotes"}
          renderAccordion={true}
        />
        <LoadingError loading={false} error={error} />
      </Container>
      <LoadingError loading={false} error={error} />
      {result && <BiddingFilterResult result={result} />}
    </MainTemplate>
  );
};

export default BiddingFilter;
