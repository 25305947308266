export function date(date: number): string {
  if (!date) {
    return "N/A";
  }
  let dateToString = String(date);
  let year = dateToString.substring(0, 4);
  let month = dateToString.substring(4, 6);
  let day = dateToString.substring(6, 8);

  return `${day}/${month}/${year}`;
}

export function percent(percentual: number): string {
  return `${percentual}%`;
}

export function numberFormat(value: number): string {
  return new Intl.NumberFormat("pt-BR").format(value);
}
