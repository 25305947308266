import React from "react";
import { Container } from "react-bootstrap";

function LoadingError({
  loading,
  error,
}: {
  loading: boolean;
  error: Error | string;
}) {
  return (
    <>
      {loading && (
        <Container fluid className="bg-white p-4 mt-4">
          Carregando...
        </Container>
      )}
      {error && (
        <Container fluid className="bg-white p-4 mt-4">
          {!!Object.keys(error)?.length ? JSON.stringify(error) : 'Página em manutenção.'}
        </Container>
      )}
    </>
  );
}

export default LoadingError;
