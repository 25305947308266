import React, { memo, PropsWithChildren } from "react";

import Header from "../../molecules/Header/Header";

const HeaderMemo = memo(Header);

function AuthTemplate({ children }: PropsWithChildren<{}>) {
	return (
		<div className="d-flex h-100">
			<HeaderMemo />
			{children}
		</div>
	);
}

export default AuthTemplate;
