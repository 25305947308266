import styled, { css } from "styled-components";

import { AgencyReportColor } from "../../../interfaces/models/AgencyDetail";
import { colors } from "../../../styles/colors";

export interface AgencyHeaderTheme {
	color: AgencyReportColor;
}

type ThemeProp = { theme: AgencyHeaderTheme };

export const Description = styled.p.attrs({
	className: "h7 m-0 line-1",
})(
	({ theme: { color } }: ThemeProp) => css`
		opacity: 0.7;
		${color === "white" && colors.blue.gray}
	`
);

export const Background = styled.div.attrs({
	className: "d-flex align-items-center flex-row p-4 rounded",
})(({ theme: { color } }: ThemeProp) => {
	switch (color) {
		case "green":
			return css`
				background: linear-gradient(to right, #4dc79f 0%, #2cbd8c 100%);
				background-size: cover;
				color: white;
			`;
		case "red":
			return css`
				background: linear-gradient(to right, #ed7277 0%, #ea2c35 100%);
				color: white;
			`;
		default:
			return css`
				background: white;
			`;
	}
});

export const IconBg = styled.div.attrs({
	className: "rounded-circle d-flex align-items-center justify-content-center",
})(
	({ theme: { color } }: ThemeProp) => css`
		height: 45px;
		width: 45px;
		background-color: rgba(
			${color === "white" ? "0,0,0, .1" : "255,255,255, 0.25"}
		);
	`
);
