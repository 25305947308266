/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import MT, { MaterialTableProps, Options } from "material-table";

import { CommonTableColumn } from "../../../interfaces/APIInterface";
import renderColumn from "../../../utils/renderColumn";
import {
  detailRender,
  DetailTypes,
  localization,
  tableIcons,
} from "./MaterialTable.utils";

interface MaterialTableNewProps
  extends Omit<MaterialTableProps<object>, "columns"> {
  noShadow?: boolean;
  sticky?: boolean;
  searchLeft?: boolean;
  columnWithOptions?: CommonTableColumn[];
  columns?: object[];
  hasSearch?: boolean;
  className?: string;
  detail?: DetailTypes;
}

function MaterialTable({
  noShadow,
  sticky,
  options,
  searchLeft,
  hasSearch = true,
  columnWithOptions,
  columns,
  data,
  detail,
  ...props
}: MaterialTableNewProps) {
  const convertedColumns = useMemo(
    () => (columnWithOptions ? renderColumn(columnWithOptions) : columns),
    [columnWithOptions, columns]
  );

  // No Shadow Style
  const components = {
    ...(noShadow
      ? {
          Container: (props) => <div {...props} />,
        }
      : {}),
    ...(!props.title && !hasSearch
      ? {
          Toolbar: () => null,
        }
      : {}),
  };

  const defaultOptions: Options<any> = {
    // Sticky Style
    ...(sticky && data && data.length < 100
      ? {
          headerStyle: {
            ...(options?.headerStyle as any),
            position: "sticky",
            top: 0,
          },
          maxBodyHeight: 600,
          paging: false,
        }
      : {}),
    ...(data &&
      data.length > 100 && {
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 15, 25, 50],
      }),
    // Search Left
    ...(searchLeft && hasSearch && !props.title
      ? {
          searchFieldAlignment: "left" as any,
          searchFieldStyle: {
            marginLeft: -12,
          },
        }
      : {}),
    ...(!props.title ? { showTitle: false } : {}),
    ...(!hasSearch
      ? {
          search: false,
        }
      : {}),
    ...options,
  };

  // bold header
  const bold = { fontWeight: "bold" } as any;
  defaultOptions.headerStyle = defaultOptions.headerStyle
    ? { ...defaultOptions.headerStyle, ...bold }
    : bold;

  const detailPanel = useMemo(() => detailRender(detail), [detail]);

  return (
    <MT
      components={components}
      {...props}
      data={data}
      columns={convertedColumns}
      options={defaultOptions}
      icons={tableIcons as any}
      localization={{ ...localization }}
      detailPanel={detailPanel}
    />
  );
}

export default MaterialTable;
