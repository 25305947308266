import React, { useState } from "react";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { Container, Button, Form } from "react-bootstrap";
import { useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";

// import logo from "../assets/images/logo.svg";
import { logoff  } from "../store/actions/LoginActions";
import { change_user_id_base } from "../store/actions/LoginActions";
import { RootReducers } from "../store/reducers";
import useConnectedSelector from "../utils/useConnectedSelector";

const schema = yup.object({
	base: yup.number().required()
});

const redirection = "/login";

const Gradient = styled.div`
	background: linear-gradient(90deg, #394e63 -8.05%, #263544 100%);
`

const Divider = styled.div`
	background: rgb(225, 229, 237);
	height: 4px;
	width: 100%;
`;

const renderError = (error: Error): string => {
	console.dir(error);
	switch (error.message) {
		case "Unauthorized":
			return "Usuário e/ou senha incorretos";
		default:
			return error.message;
	}
};

function ChangeBase() {
	const { dispatch } = useStore();
	const history = useHistory();
	const [error, setError] = useState<Error>();
	const { loading, data: loginData } = useSelector(
		(state: RootReducers) => state.LoginReducer
	);
  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);


const onSubmit = async ({
	base,
}: {
	base: number;
}) => {
	if (error) {
		setError(undefined);
	}
	try {
		await change_user_id_base(auth, base)(dispatch);
		logoff(dispatch);
		history.push(redirection);
	} catch (e) {
		setError(e);
	}
};
return (
	<MainTemplate withoutContainer>
		<Container fluid className="p-0">
			<div className="px-5 py-4 pb-5 bg-white rounded-bottom">
				<h3 className="text-center" style={{ color: '#808080' }}>Altere a base de licitações aqui</h3>
				<p className="text-center" style={{ color: '#808080' }}>Após alterar a base você será deslogado da aplicação</p>
				<Formik
					validationSchema={schema}
					initialValues={{ base: 1 }}
					onSubmit={onSubmit}
				>
					{({ handleSubmit, handleChange, values }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group controlId="base">
								<Form.Label className="h7 font-weight-semibold">
									Nova Base
								</Form.Label>
								<Form.Control
									required
									as="select"
									name="base"
									value={values.base}
									onChange={handleChange}
								>
									<option disabled value="">Selecione a nova base</option>
									<option value="1">Estadual</option>
									<option value="2">Federal</option>
								</Form.Control>
							</Form.Group>
							{loading && (
								<p className="m-0 mt-4 text-secondary">Carregando</p>
							)}
							{error && (
								<p className="m-0 mt-4 text-danger">{renderError(error)}</p>
							)}

							<div className="d-flex justify-content-center pt-4">
								<Button
									className="gradient-button h8 py-2 px-5 h8"
									type="submit"
								>
									Alterar a base
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Container>
	</MainTemplate>
);

}

export default ChangeBase;
