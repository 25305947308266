import { FilterGroup } from "../../../interfaces/models/FilterInterface";
import { LOGIN_LOGOFF } from "../Login/constants";

export const BIDDING_FILTERS_LIST_LOADING = "BIDDING_FILTERS_LIST_LOADING";
export const BIDDING_FILTERS_LIST_SUCCESS = "BIDDING_FILTERS_LIST_SUCCESS";
export const BIDDING_FILTERS_LIST_ERROR = "BIDDING_FILTERS_LIST_ERROR";

export interface BiddingFiltersListState {
  loading: boolean;
  loaded: boolean;
  data?: FilterGroup[];
  error?: string;
}

export const initialState: BiddingFiltersListState = {
  loading: false,
  loaded: false,
};

export type Action = {
  type: string;
  payload?: FilterGroup[];
  error?: string;
};

export default function BiddingFiltersListReducer(
  state = initialState,
  action: Action
): BiddingFiltersListState {
  switch (action.type) {
    case BIDDING_FILTERS_LIST_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case BIDDING_FILTERS_LIST_SUCCESS:
      return {
        ...initialState,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    case BIDDING_FILTERS_LIST_ERROR:
      return {
        ...initialState,
        loaded: true,
        error: action.error,
      };
    case LOGIN_LOGOFF:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
