import React from "react";

import { OnHover } from "../../../interfaces/models/CommonTypes";
import formatMoney from "../../../utils/moneyFormat";

export function CustomTooltip({ active, payload, label, formatter }: any) {
	if (active) {
		const onhover: OnHover[] = payload[0].payload.onhover;
		return (
			<div className="bg-white p-3 rounded border border-light-gray mb-1">
				<p className="font-weight-bold mb-2">{label}</p>
				<p>{formatMoney(formatter, true)(payload[0].value)}</p>
				{onhover.map(({ title, value }, index) => (
					<p
						key={title}
						className={index === onhover.length - 1 ? "mb-0" : "mb-2"}
					>
						<span className="font-weight-semibold">{title}</span>: {value}
					</p>
				))}
			</div>
		);
	}

	return null;
}
