import React from "react";
import styled, { css } from "styled-components";

import { MultibarGraphProp } from "../../../interfaces/models/CommonTypes";
import formatMoney from "../../../utils/moneyFormat";

export const fixColor = (color?: string): string => {
	if (!color) return "";
	return color[0] === "#" ? color : `#${color}`;
};

const CategoryButton = styled.button<{ active: boolean }>(
	({ active }) => css`
		display: flex;
		align-items: center;
		appearance: none;
		background: transparent;
		padding: 0.25rem 0.75rem;
		margin: 0 0.25rem 0 0;
		border: 0;
		cursor: pointer;
		transition: 0.1s ease-in-out opacity;
		opacity: ${active ? 1 : 0.5};
		:hover {
			opacity: 0.75;
		}
	`
);

const CategoryColor = styled.span<{ color: string; mr?: string }>(
	({ color, mr }) => css`
		display: inline-block;
		width: 0.85rem;
		height: 0.85rem;
		margin-right: ${mr ?? "0.35rem"};
		background: ${color || "#ddd"};
		border-radius: 4px;
	`
);

export function CategoryToggler({
	category: { value, label, color },
	onClick,
	active,
}: {
	category: MultibarGraphProp["categories"][0];
	onClick: (value: string) => void;
	active: boolean;
}) {
	return (
		<CategoryButton
			active={active}
			type="button"
			onClick={() => onClick(value)}
		>
			<CategoryColor color={fixColor(color)} /> {label}
		</CategoryButton>
	);
}
export function CustomTooltip({
	active,
	payload,
	label,
	formatter,
	categories,
	activeCategories,
}: any) {
	console.log(formatter);
	const formatterMoney = formatMoney(formatter ?? "monetario", true);
	if (active) {
		return (
			<div className="bg-white p-3 rounded border border-light-gray mb-1">
				<p className="font-weight-bold mb-4">{label}</p>
				{payload.map(
					(p, index) =>
						activeCategories[p.dataKey] && (
							<p
								key={p.dataKey}
								className={index === payload.length - 1 ? "mb-0" : "mb-2"}
							>
								<CategoryColor color={p.color} mr=".15rem" />{" "}
								<b>{categories[p.dataKey].label}</b>: {formatterMoney(p.value)}
							</p>
						)
				)}
			</div>
		);
	}

	return null;
}
