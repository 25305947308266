import React, { useMemo, useState } from "react";
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import { MultibarGraphProp } from "../../../interfaces/models/CommonTypes";
import formatMoney from "../../../utils/moneyFormat";
import {
	CategoryToggler,
	CustomTooltip,
	fixColor,
} from "./MultipleBarGraph.styles";

const hideAxisStyle = {
	tick: false,
	tickMargin: 0,
	tickSize: 0,
	height: 1,
};

export default function MultipleBarGraph({
	data: { categories, contentType, data },
	hideXAxisLabel,
}: {
	data: MultibarGraphProp;
	hideXAxisLabel?: boolean;
}) {
	const [selected, setSelected] = useState<{ [key: string]: boolean }>(() =>
		categories.reduce((prev, curr) => ({ ...prev, [curr.value]: true }), {})
	);

	const hideXAxis = hideXAxisLabel ? hideAxisStyle : {};
	const bars = useMemo(
		() =>
			Object.keys(selected)
				.filter((item) => selected[item])
				.map((value) => categories.find((el) => el.value === value)),
		[selected]
	);
	const mapCategories = useMemo(
		() =>
			categories.reduce((prev, curr) => ({ ...prev, [curr.value]: curr }), {}),
		[categories]
	);

	const toggleSelected = (value: string) => {
		setSelected({ ...selected, [value]: !selected[value] });
	};

	return (
		<>
			<ResponsiveContainer width="100%" height={350}>
				<BarChart data={data}>
					<XAxis dataKey="label" {...hideXAxis} />
					<YAxis type="number" tickFormatter={formatMoney(contentType)} />
					<Tooltip
						content={
							<CustomTooltip
								categories={mapCategories}
								activeCategories={selected}
								formatter={contentType}
							/>
						}
					/>
					{bars.map((bar) => (
						<Bar
							dataKey={bar.value}
							key={bar.value}
							fill={fixColor(bar.color)}
							isAnimationActive={false}
							radius={5}
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
			<div className="d-flex justify-content-center">
				{categories.map((category) => (
					<CategoryToggler
						key={category.value}
						active={selected[category.value]}
						category={category}
						onClick={toggleSelected}
					/>
				))}
			</div>
		</>
	);
}
