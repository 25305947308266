import {AuthToken} from "../../../interfaces/AuthInterface";
import {
    GetBiddingListAPI
} from "../../../services/bidding/biddingListApi";
import { Dispatch } from "redux";

import {
    BIDDING_LIST_LOADING,
    BIDDING_LIST_SUCCESS,
    BIDDING_LIST_ERROR,
} from "../../reducers/Bidding/BiddingListReducer";

const loadingState = () => ({
    type: BIDDING_LIST_LOADING,
});

const successState = (payload) => ({
    type: BIDDING_LIST_SUCCESS,
    payload,
});

const errorState = (error: string) => ({
    type: BIDDING_LIST_ERROR,
    error,
});

export const sendBiddingList = (
    auth: AuthToken,
    dispatch: Dispatch
) => async (filters: any) => {
    dispatch(loadingState());
    try {
        const result = await GetBiddingListAPI(auth, filters?.input ?? {});
        dispatch(successState(result));
    } catch (error) {
        dispatch(errorState(error));
    }
};