import { AuthToken } from "../../interfaces/AuthInterface";
import { API } from "../api";

export interface Options {
    url: string;
    field: string;
}

export interface Render {
    type: string;
    options: Options;
}

export interface Coluna {
    title: string;
    field: string;
    render: Render;
}

export interface Linha {
    nome: string;
    cnpj: string;
    valor_contratos: number;
    valor_contratos_aditivos: number;
    valor_propostas: number;
    valor_pagamentos: number;
    cnpj_hidden: string;
}

export interface Dados {
    colunas: Coluna[];
    linhas: Linha[];
}

export interface CompaniesTableSearchTabela {
    ordem: number;
    titulo: string;
    descricao: string;
    dados: Dados;
}

export interface CompaniesTableSearchResponse {
    tabelas: CompaniesTableSearchTabela[];
}

export async function CompaniesTableSearchAPI(
  auth: AuthToken,
  termo: string
): Promise<CompaniesTableSearchTabela[]> {
  try {
    const res: CompaniesTableSearchResponse = await API("buscar", {
      method: "post",
      headers: {
	    "Authorization": "Token " + auth.auth
      },
      body: JSON.stringify({ ...auth, termo }),
    });

    return res.tabelas;
  } catch (error) {
    throw new Error(error);
  }
}
