import React from "react";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from '@material-ui/core/Tooltip';

const IconWrapper = styled.span`
  width: 20px;
  font-size: 20px;
  margin: 0px 0px 8px 5px;
`;

type TooltipProps = {
  tooltip: string;
};

const TooltipUI = ({ tooltip }: TooltipProps) => {
  return (
    <>
      <Tooltip title={tooltip} placement={'top-start'}>
        <IconWrapper>
          <FontAwesomeIcon icon={"question-circle"} size={'sm'}/>
        </IconWrapper>
      </Tooltip>
    </>
  );
};

export default TooltipUI;
