import React from "react";
import styled, { css } from "styled-components";

const Container = styled.label`
	position: relative;
	margin: 0;
	padding: 0;
`;
const Check = styled.input`
	position: absolute;
	opacity: 0;
	margin: 0;
	padding: 0;
`;
const Custom = styled.div(
	({ value }: CheckboxProp) => css`
		width: 1rem;
		height: 1rem;
		border-radius: 0.25rem;
		background: ${value ? "#239EEF" : "transparent"};
		border-width: ${value ? 0 : 2}px;
		border-style: solid;
		border-color: #239eef;
		position: relative;
		color: white;
		font-size: 0.85rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
	`
);

interface CheckboxProp {
	value?: boolean;
}

function Checkbox({ value = false }: CheckboxProp) {
	return (
		<Container>
			<Check type="checkbox" readOnly checked={value} />
			<Custom value={value}>
				{value ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fillRule="evenodd"
						clipRule="evenodd"
						viewBox="0 0 24 24"
					>
						<path
							fill="white"
							d="M21 6.285L9.84 19.018 3 13l1.319-1.49 5.341 4.686L19.525 5 21 6.285z"
						/>
					</svg>
				) : (
					""
				)}
			</Custom>
		</Container>
	);
}

export default Checkbox;
