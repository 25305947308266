import React, { Dispatch, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import CompanyDetailTemplate from "../components/template/CompanyDetailTemplate/CompanyDetailTemplate";
import LoadingContainer from "../components/template/LoadingContainer/LoadingContainer";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { AuthToken } from "../interfaces/AuthInterface";
import { CompanyDetailResponse } from "../interfaces/models/CompanyDetail";
import { CompanyDetailAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<CompanyDetailResponse>();

const getCompany =
  (auth: AuthToken, cnpj: string) =>
  async (dispatch: Dispatch<SearchAction<CompanyDetailResponse>>) => {
    dispatch({ type: "loading" });
    try {
      const company = await CompanyDetailAPI(auth, cnpj);
      dispatch({ type: "loaded", result: company });
    } catch (error) {
      dispatch({ type: "error", error });
    }
  };

const Company = () => {
  const [{ loading, result, error }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);
  const { id } = useParams<any>();

  useEffect(() => {
    getCompany(auth, id)(dispatch);
  }, []);

  return (
    <MainTemplate title="Detalhamento de empresa" withoutContainer>
      {loading ? <LoadingContainer className="mt-5 pt-5" /> : null}
      {result && <CompanyDetailTemplate company={result} />}
    </MainTemplate>
  );
};

export default Company;
