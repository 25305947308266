import React, { memo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
	width: "100%",
	height: "400px",
};

function Map({ lat, lng }: { lat: number; lng: number }) {
	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={{ lat, lng }}
				zoom={17}
			>
				<Marker position={{ lat, lng }} />
			</GoogleMap>
		</LoadScript>
	);
}

export default memo(Map);
