import { ArrowDropDown, InfoOutlined, Print } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Card, Accordion, Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { listUfBrasil } from "../../../data/ufList";
import { PrintRiskThemeItem } from "../../../interfaces/models/PrintRiskThemeItem";
import { PrintRiskThemeItemDetail } from "../../../interfaces/models/PrintRiskThemeItemDetail";
import { RiskThemeCompaniesByUFAPI } from "../../../services/api";
import { numberFormat } from "../../../utils/formatter";
import formatMoney from "../../../utils/moneyFormat";
import useConnectedSelector from "../../../utils/useConnectedSelector";
import CompanyResume from "./CompanyResume";
import Dashboard from "./Dashboard";

function Theme(props) {
  const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);
  const themeData: PrintRiskThemeItem = props.themeData;
  const uf: string = props.uf;

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState<PrintRiskThemeItemDetail>();

  const callPrint = useReactToPrint({
    content: () =>
      document.querySelector<HTMLElement>(`#print-${themeData.tema}`) ??
      new HTMLElement(),
    documentTitle: `${themeData.titulo} - ${uf}`,
  });

  useEffect(() => {
    async function getDetail() {
      setLoading(true);
      const data = await RiskThemeCompaniesByUFAPI(auth, uf, themeData.tema);
      setDetailData(data);
      setLoading(false);
    }
    if (isOpen) getDetail();
  }, [uf, isOpen]);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Accordion>
      <Accordion.Toggle
        as={Card.Header}
        className="bg-white h6 font-weight-normal pl-0 pt-4 row w-100"
        eventKey="keycollapse"
        onClick={handleClickOpen}
      >
        <span className="col-6">
          {themeData.titulo}{" "}
          <span title={themeData.descricao}>
            <InfoOutlined />
          </span>
        </span>
        <span
          className="col-2"
          title={formatMoney(
            "monetario",
            true
          )(themeData.valor_contratos_total).toString()}
        >
          {formatMoney("monetario")(themeData.valor_contratos_total)}
        </span>
        <span
          className="col-2"
          title={formatMoney(
            "monetario",
            true
          )(themeData.valor_contratos_estado).toString()}
        >
          {formatMoney("monetario")(themeData.valor_contratos_estado)}
        </span>
        <span className="col">{numberFormat(themeData.entidades)}</span>
        <Button
          className="p-0 px-1 ml-2 bg-white text-dark col border-0"
          style={{ transform: isOpen ? "rotate(180deg)" : "" }}
        >
          <ArrowDropDown className="" />
        </Button>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={"keycollapse"}>
        <Card.Body
          id={`print-${themeData.tema}`}
          className="print-container print p-0"
        >
          {loading ? (
            <div className="col text-center p-4">Carregando...</div>
          ) : (
            <>
              <div className="cover only-print">
                <img
                  src={require("./../../../assets/images/report-bottom.png")}
                  className="w-100"
                  style={{ height: "20em" }}
                />
                <div className="p-5 text-center">
                  <h2>
                    Relatório de Achados <br />
                    de Risco de Fraude | RARF
                  </h2>
                  <p className="fs-4">
                    Tema de risco: {themeData.titulo}
                    <br />
                    UF das Unidades Gestoras:{" "}
                    {listUfBrasil.find((item) => item.value === uf).description}
                  </p>
                  <p className="font-bold mb-5 fs-4">
                    <strong>
                      {new Date().getFullYear() - 5} -{" "}
                      {new Date().getFullYear()}
                    </strong>
                  </p>
                  <span className="mt-5 text-secondary"> CONFIDENCIAL </span>
                </div>
                <img
                  src={require("./../../../assets/images/report-up.png")}
                  className="w-100"
                  style={{
                    height: "20em",
                    bottom: "0",
                    position: "absolute",
                  }}
                />
              </div>
              <div className="print-avoid-break">
                <h1
                  className="m-0 p-0 mb-2 only-print"
                  style={{ marginTop: "-5mm" }}
                >
                  {themeData.titulo}
                </h1>
                <h3 className="mb-4 only-print">
                  UF:{" "}
                  {listUfBrasil.find((item) => item.value === uf).description} |{" "}
                  {new Date().getFullYear() - 5} - {new Date().getFullYear()}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: themeData.descricao,
                  }}
                ></p>
                <Dashboard data={themeData} className="only-print m-0 p-0" />
              </div>
              <Button className="no-print mb-4 ml-0" onClick={callPrint}>
                <Print /> Imprimir relatório
              </Button>
              {detailData?.empresas?.map((empresa) => (
                <CompanyResume
                  company={empresa}
                  key={`company-${empresa.documento}`}
                />
              ))}
            </>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default Theme;
