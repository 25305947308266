import { Print } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { CompanyDetailResponse } from "../../../interfaces/models/CompanyDetail";
import { CompanyDetailReportAPI } from "../../../services/api";
import cnpjMask from "../../../utils/cnpjMask";
import useConnectedSelector from "../../../utils/useConnectedSelector";
import WarningHeader from "../../molecules/WarningHeader";
import ContentOrderer from "../../organisms/ContentOrderer/ContentOrderer";
import LoadingContainer from "../LoadingContainer/LoadingContainer";

type CompanyDetailTemplateProps = {
  company: CompanyDetailResponse;
};

const CompanyDetailTemplate = ({
  company: { nome, cnpj, tabelas, graficos, mapa, cabecalho, iframes, cards },
}: CompanyDetailTemplateProps) => {
  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);
  const [printElements, setPrintElements] = useState<JSX.Element[]>();
  const [loading, setLoading] = useState<boolean>();
  const searchParams = useLocation().search;

  const renderedElements = useMemo(
    () =>
      ContentOrderer({
        tables: tabelas,
        graphs: graficos,
        map: mapa,
        iframes,
        cards,
      }),
    [tabelas, graficos, mapa]
  );

  const callPrint = useReactToPrint({
    content: () =>
      document.querySelector<HTMLElement>("#print-report") ?? new HTMLElement(),
    documentTitle: nome,
  });

  const getPrintCompanyDetails = async (cnpj) => {
    if (loading) return;
    setLoading(true);
    let company;
    try {
      company = await CompanyDetailReportAPI(auth, cnpj);
    } catch (error) {
      setLoading(false);
    }
    const { cards, tables, graficos, mapa, iframes } = company;
    setPrintElements(
      ContentOrderer({
        tables,
        graphs: graficos,
        map: mapa,
        iframes,
        cards,
      })
    );
    callPrint();
    setLoading(false);
  };

  useEffect(() => {
    function getDataToPrint() {
      const print = new URLSearchParams(searchParams).get("print");
      if (print) getPrintCompanyDetails(cnpj);
    }
    getDataToPrint();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingContainer className="mt-5 pt-5" />
      ) : (
        <>
          <Container className="p-0 d-flex justify-content-between align-items-center mb-3">
            <h3 className="h4 font-weight-bold mt-1">
              {nome} ({cnpjMask(cnpj)}){" "}
            </h3>
            <Button onClick={() => getPrintCompanyDetails(cnpj)}>
              <Print />{" "}
              {loading ? "Carregando relatório..." : "Imprimir relatório"}
            </Button>
          </Container>
          <WarningHeader content={cabecalho} />
          {renderedElements}
        </>
      )}
      <div id="print-report" className="only-print">
        <div className="cover only-print">
          <img
            src={require("./../../../assets/images/report-bottom.png")}
            className="w-100"
            style={{ height: "20em" }}
          />
          <div className="text-center mt-5 pt-5">
            <h2 className="mt-5">Relatório Específico</h2>
            <h4 className="mt-5 mb-3">{nome}</h4>
            <h4 className="mb-5">{cnpjMask(cnpj)}</h4>
            <span className="pt-5 mt-5 text-secondary"> CONFIDENCIAL </span>
          </div>
          <img
            src={require("./../../../assets/images/report-up.png")}
            className="w-100"
            style={{
              height: "20em",
              bottom: "0",
              position: "absolute",
            }}
          />
        </div>

        <div className="print-margin">
          <div className="p-4">
            <h4 className="h5 font-weight-bold">Padrões de risco detectados</h4>
            <WarningHeader content={cabecalho} />
          </div>
          {printElements}
        </div>
      </div>
    </>
  );
};

export default CompanyDetailTemplate;
