export type ColorProps = {
  light: string;
  primary: string;
  gray: string;
};

export const colors = {
  blue: {
    light: "#3fbbff",
    primary: "#495bd4",
    gray: "#304260",
  },
  white: "#fff",
  greyFont: "#6E839A",
  greyProgress: '#E4E1E1',
  greyShadow: "#DCE3F3B0",
  red: "#ef0708",
  yellow: "#ffc300",
};
