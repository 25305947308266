/* eslint-disable import/named */
import React, { Dispatch, useEffect, useReducer } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthToken } from "../interfaces/AuthInterface";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import menu from "../data/menu";
import { GetBaseAPI } from "../services/api";
import SearchReducer, {
	SearchAction,
} from "../components/reducers/SearchReducer";
import { GetBaseResume } from "../interfaces/models/GetBaseResume";
import useConnectedSelector from "../utils/useConnectedSelector";

const menuData = menu.filter(({ id }) => id !== "/home" && id !== "/logoff");

const HeightLink = styled(Link).attrs({
  className: "bg-white d-flex flex-column justify-content-between p-4 rounded",
})`
  height: 138px;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: background;

  .link__icon,
  .link__name {
    transition: 0.1s ease-in-out color;
  }
  .link__name {
    color: black;
  }

  &:hover,
  &:active {
    text-decoration: none;
    background: linear-gradient(46deg, #3c5369 0%, #263544 100%);

    .link__icon,
    .link__name {
      color: white;
    }
  }
`;

const [initialState, reducer] = SearchReducer<GetBaseResume>();

const getBase = async (
  auth: AuthToken,
  dispatch: Dispatch<SearchAction<GetBaseResume>>
) => {
  dispatch({ type: "loading" });
  try {
   console.log('c')
    const result = await GetBaseAPI(auth);
    dispatch({
      type: "loaded",
      result: result,
    });
  } catch (error) {
    dispatch({ type: "error", error });
  }
  return false;
};


function Home() {
const [{ loading, result, error }, dispatch] = useReducer(reducer, initialState);
const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);
console.log(auth)
console.log(result)
useEffect(() => {getBase(auth, dispatch)}, []);
console.log(result)
return (
    <MainTemplate withoutContainer>
      <Container fluid className="p-0">
        <h3 className="h4 font-weight-bold pb-3 pl-2">
          SADRI - Sistema de Análise e Detecção de Riscos
        </h3>
	<h4>
	 Base: {result?.base}
	</h4>
        <Row noGutters>
          {menuData.map(({ name, id, icon }) => (
            <Col md={4} key={id} className="p-2">
              <HeightLink to={id}>
                <span className="h4 m-0 link__icon">
                  <FontAwesomeIcon icon={icon as IconName} />
                </span>
                <span className="h5 font-weight-semibold m-0 pt-4 link__name">
                  {name}
                </span>
              </HeightLink>
            </Col>
          ))}
        </Row>
      </Container>
    </MainTemplate>
  );
}


export default Home;
