/* eslint-disable react/display-name */
import React, { Dispatch, useEffect, useReducer } from "react";
import { Container } from "react-bootstrap";

import MaterialTable from "../components/atoms/MaterialTable/MaterialTable";
import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { AuthToken } from "../interfaces/AuthInterface";
import { BeneficiariesResume } from "../interfaces/models/BeneficiariesResume";
import { BeneficiariesAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<BeneficiariesResume>();

const getBeneficiaries = async (
  auth: AuthToken,
  dispatch: Dispatch<SearchAction<BeneficiariesResume>>
) => {
  dispatch({ type: "loading" });
  try {
    const result = await BeneficiariesAPI(auth);
    dispatch({ type: "loaded", result });
  } catch (e) {
    dispatch({ type: "error", error: e });
  }
};

function Beneficiaries() {
  const [{ loading, error, result }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);

  useEffect(() => {
    getBeneficiaries(auth, dispatch);
  }, []);

  return (
    <MainTemplate title="Consultar Beneficiários" withoutContainer>
      <LoadingError loading={loading} error={error} />
      {result && (
        <Container fluid className="bg-white p-4 mt-4">
          {result.title && (
            <h3 className="h5 font-weight-bold">{result.title}</h3>
          )}
          {result.descricao && <p className="mt-4">{result.descricao}</p>}
          <MaterialTable
            noShadow
            columnWithOptions={result?.colunas ?? []}
            data={result.linhas}
            searchLeft={result.searchable !== false}
            sticky
          />
        </Container>
      )}
    </MainTemplate>
  );
}

export default Beneficiaries;
