import { LOGIN_LOGOFF } from "../Login/constants";
import { BiddingListResponse } from "../../../services/bidding/biddingListApi";

export const BIDDING_LIST_LOADING = "BIDDING_LIST_LOADING";
export const BIDDING_LIST_SUCCESS = "BIDDING_LIST_SUCCESS";
export const BIDDING_LIST_ERROR = "BIDDING_LIST_ERROR";

export interface BiddingListState {
    loading: boolean;
    loaded: boolean;
    data?: BiddingListResponse;
    error?: string;
}

export const initialState: BiddingListState = {
    loading: false,
    loaded: false,
};

export type Action = {
    type: string;
    payload?: BiddingListResponse;
    error?: string;
};

export default function BiddingListReducer(
    state = initialState,
    action: Action
): BiddingListState {
    switch (action.type) {
        case BIDDING_LIST_LOADING:
            return {
                ...initialState,
                loading: true,
            };
        case BIDDING_LIST_SUCCESS:
            return {
                ...initialState,
                loading: false,
                loaded: true,
                data: action.payload,
            };
        case BIDDING_LIST_ERROR:
            return {
                ...initialState,
                loaded: true,
                error: action.error,
            };
        case LOGIN_LOGOFF:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
