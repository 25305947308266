import {
	LOGIN_ERROR,
	LOGIN_LOADING,
	LOGIN_LOGOFF,
	LOGIN_SUCCESS,
} from "./constants";

export interface LoginAuth {
	auth: string;
	id: number;
}

export interface LoginState {
	loading: boolean;
	data?: LoginAuth;
	error?: string;
}

const initialState: LoginState = {
	loading: false,
};

type Action = { type: string; payload?: LoginAuth; error?: string };

export default function LoginReducer(
	state = initialState,
	action: Action
): LoginState {
	switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...initialState,
        loading: false,
        data: {
          ...action.payload,
        },
      };
    case LOGIN_ERROR:
      return {
        ...initialState,
        error: action.error,
      };
    case LOGIN_LOGOFF:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
