/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Dispatch, useMemo, useReducer } from "react";
import { Container } from "react-bootstrap";

import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import FilterForm from "../components/template/FilterForm/FilterForm";
import FilterResults from "../components/template/FilterResults/FilterResults";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { AuthToken, IsLoggedProps } from "../interfaces/AuthInterface";
import { FilterResultResponse } from "../interfaces/models/FilterResultResponse";
import { FilterAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<FilterResultResponse>();

export const sendFilter = (
  auth: AuthToken,
  dispatch: Dispatch<SearchAction<FilterResultResponse>>
) => async (filter: any) => {
  dispatch({ type: "loading" });
  try {
    const result = await FilterAPI(auth, filter?.input ?? {});
    dispatch({ type: "loaded", result });
  } catch (error) {
    dispatch({ type: "error", error });
  }
};

interface FilterPageProps {}

export default function Filter({}: IsLoggedProps<FilterPageProps>) {
  const [{ result, loading, error }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const { filters, auth } = useConnectedSelector(
    ({ FiltersReducer, LoginReducer }) => ({
      auth: LoginReducer.data,
      filters: FiltersReducer.data,
    })
  );

  const sendFilterMemo = useMemo(() => sendFilter(auth, dispatch), [auth]);

  return (
    <MainTemplate title="Filtrar por padrões de risco" withoutContainer>
      <Container fluid className="bg-white mt-4 pt-0 px-4 pb-4 rounded">
        <FilterForm
          sendFilter={sendFilterMemo}
          filters={filters}
          loading={loading}
          filterLabel={'Filtrar Empresas'}
        />
      </Container>
      <LoadingError loading={false} error={error} />
      {result && <FilterResults result={result} />}
    </MainTemplate>
  );
}
