import React from "react";
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import {
	GraphContent,
	GraphData,
} from "../../../interfaces/models/CommonTypes";
import formatMoney from "../../../utils/moneyFormat";
import { CustomTooltip } from "./BarGraph.styles";

function BarGraph({
	data,
	content,
	hideXAxisLabel,
}: {
	data: GraphData[];
	content: GraphContent;
	hideXAxisLabel: boolean;
}) {
	const hideXAxis = hideXAxisLabel
		? {
				tick: false,
				tickMargin: 0,
				tickSize: 0,
				height: 1,
		  }
		: {};

	return (
		<ResponsiveContainer width="100%" height={350}>
			<BarChart data={data}>
				<XAxis dataKey="texto" {...hideXAxis} />
				<YAxis
					dataKey="valor"
					type="number"
					tickFormatter={formatMoney(content)}
				/>
				<Tooltip content={<CustomTooltip formatter={content} />} />
				<Bar dataKey="valor" fill="#2171b5" radius={5} />
			</BarChart>
		</ResponsiveContainer>
	);
}

export default BarGraph;
