import React from "react";

import { CommonGraphContainer } from "../../../interfaces/models/CommonTypes";
import GraphBlock from "../../molecules/Blocks/GraphBlock";
import MapBlock from "../../molecules/Blocks/MapBlock";
import TableBlock from "../../molecules/Blocks/TableBlock";
import { IFrameInterface } from "../../../interfaces/models/CompanyDetail";
import IFrameBlock from "../../molecules/Blocks/IFrameBlock";

import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { Flex } from "rebass";

import gfm from "remark-gfm";
import CardBlock from "../../molecules/Blocks/CardBlock";

const WrapperMarkdown = styled(Flex)`
  a {
    text-decoration: underline;
  }
`;

export const renderDescription = (
  descricao: string | null,
  className: string
) => {
  if (descricao) {
    return (
      <WrapperMarkdown>
        <p className={className}>
          <ReactMarkdown plugins={[gfm]} source={descricao} />
        </p>
      </WrapperMarkdown>
    );
  }

  return null;
};

const sorterOrder = ({ order: orderA }, { order: orderB }) => {
  if (orderA < orderB) return -1;
  if (orderA > orderB) return 1;
  return 0;
};

type ContentOrdererProps = {
  tables?: any;
  graphs?: CommonGraphContainer[];
  map?: any;
  iframes?: IFrameInterface[];
  cards?: any[];
};

const ContentOrderer = ({
  tables,
  graphs,
  map,
  iframes,
  cards,
}: ContentOrdererProps) => {
  const cardsArray = cards
    ? cards.map((card) => ({
        order: card.ordem,
        type: "card",
        render: card,
      }))
    : [];

  const tablesArray = tables
    ? tables.map((table) => ({
        order: table.ordem,
        type: "table",
        render: table,
      }))
    : [];

  const graphsArray = graphs
    ? graphs.map((graph) => ({
        order: graph.ordem,
        type: "graph",
        render: graph,
      }))
    : [];

  const mapArray = map ? [{ order: map.ordem, type: "map", render: map }] : [];

  const iframesArray = iframes
    ? iframes.map((iframe) => ({
        order: iframe.ordem,
        type: iframe.tipo,
        render: iframe,
      }))
    : [];

  return [
    ...cardsArray,
    ...tablesArray,
    ...graphsArray,
    ...mapArray,
    ...iframesArray,
  ]
    .sort(sorterOrder)
    .map(({ type, render }) => {
      switch (type) {
        case "graph":
          return <GraphBlock graph={render as any} key={render.titulo} />;
        case "map":
          return <MapBlock map={render as any} key={render.titulo} />;
        case "table":
          return <TableBlock table={render as any} key={render.titulo} />;
        case "iframe":
          return <IFrameBlock iframe={render as any} key={render.titulo} />;
        case "card":
          return <CardBlock card={render as any} key={render.titulo} />;
        default:
          return undefined;
      }
    });
};

export default ContentOrderer;
