import React from "react";
import { Button } from "react-bootstrap";

import { CustomInputInterface } from "./Inputs.interface";

function BooleanInput({ value, setValue }: CustomInputInterface<boolean>) {
	const toggle = (val: boolean) => () => setValue(val === value ? null : val);

	return (
		<>
			<Button
				variant={value === true ? "primary" : "outline-primary"}
				type="button"
				size="sm"
				className="px-4 py-2"
				onClick={toggle(true)}
			>
				sim
			</Button>
			<Button
				variant={value === false ? "primary" : "outline-primary"}
				type="button"
				size="sm"
				className="ml-2 px-4 py-2"
				onClick={toggle(false)}
			>
				não
			</Button>
		</>
	);
}

export default BooleanInput;
