import React, { ReactNode, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useStore } from "react-redux";

import { fetchFilters } from "../../../store/actions/FiltersActions";
import useConnectedSelector from "../../../utils/useConnectedSelector";
import { fetchBiddingFilters } from "../../../store/actions/bidding/BiddingFiltersActions";
import { fetchBiddingFiltersList } from "../../../store/actions/bidding/BiddingFiltersListActions";

function LoadingFiltersContainer({ children }: { children?: ReactNode }) {
  const { dispatch } = useStore();
  const { auth, filtersLoading } = useConnectedSelector(
    ({ LoginReducer, FiltersReducer }) => ({
      auth: LoginReducer.data,
      filtersLoading: FiltersReducer.loading,
    })
  );

  useEffect(() => {
    const load = async () => {
      await fetchFilters(auth)(dispatch);
      await fetchBiddingFilters(auth)(dispatch);
      // await fetchBiddingFiltersList(auth)(dispatch);
    };
    load();
  }, []);

  if (!filtersLoading) return <>{children}</>;

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <div className="bg-white rounded p-4 d-flex align-items-center flex-column">
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Carregando...</span>
        </Spinner>
        <h2 className="h5 font-weight-bold mt-2 mb-3">Carregando</h2>
        <p className="mb-0">Carregando Filtros </p>
      </div>
    </div>
  );
}

export default LoadingFiltersContainer;
