import { Dispatch } from "redux";

import { AuthToken } from "../../interfaces/AuthInterface";
import { FilterGroup } from "../../interfaces/models/FilterInterface";
import { GetFiltersAPI } from "../../services/api";
import {
  FILTERS_ERROR,
  FILTERS_LOADING,
  FILTERS_SUCCESS,
} from "../reducers/Filters/constants";

const loadingState = () => ({
  type: FILTERS_LOADING,
});

const successState = (payload: FilterGroup[]) => ({
  type: FILTERS_SUCCESS,
  payload,
});

const errorState = (error: string) => ({
  type: FILTERS_ERROR,
  error,
});

export const fetchFilters = (auth: AuthToken) => {
  return async (dispatch: Dispatch) => {
    dispatch(loadingState());
    try {
      const response = await GetFiltersAPI(auth);

      dispatch(successState(response));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(errorState(e));
      return Promise.reject(e);
    }
  };
};
