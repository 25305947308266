import React from "react";
import { Helmet } from "react-helmet";
const mainTitle = "SADRI";

function HeadTitle({ title }: { title?: string }) {
	const txt = title ? `${title} | ${mainTitle}` : mainTitle;
	return (
		<Helmet>
			<title>{txt}</title>
		</Helmet>
	);
}

export default HeadTitle;
