import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faBalanceScale,
	faBuilding,
	faExclamationCircle,
	faExpandAlt,
	faFileInvoiceDollar,
	faFlag,
	faHandHoldingUsd,
	faHome,
	faHourglassStart,
	faLandmark,
	faMapMarkedAlt,
	faQuestionCircle,
	faSignOutAlt,
	faTimes,
	faUsers,
	faUserTie,
	faLink
} from "@fortawesome/free-solid-svg-icons";

export default function iconsLibrary() {
	library.add(
		faBuilding,
		faBalanceScale,
		faExclamationCircle,
		faFileInvoiceDollar,
		faFlag,
		faHandHoldingUsd,
		faHome,
		faHourglassStart,
		faLandmark,
		faTimes,
		faExpandAlt,
		faMapMarkedAlt,
		faQuestionCircle,
		faSignOutAlt,
		faUsers,
		faUserTie,
		faLink
	);
}
