import React, { useMemo } from "react";
import { Container, Row } from "react-bootstrap";

import { AgencyDetailResponse } from "../../../interfaces/models/AgencyDetail";
import ContentOrderer from "../../organisms/ContentOrderer/ContentOrderer";
import AgencyDetailHeaderEl from "./AgencyDetailHeader";

interface AgencyDetailTemplateProps {
  agency: AgencyDetailResponse;
}

function AgencyDetailTemplate({
  agency: { nome, cabecalho, resumo, tabelas, graficos },
}: AgencyDetailTemplateProps) {
  const renderedElements = useMemo(
    () =>
      ContentOrderer({
        tables: tabelas,
        graphs: graficos,
      }),
    [tabelas, graficos]
  );

  const getResultMessage = () => {
    if (resumo && resumo.length > 0) {
      return <p>As empresas de alto risco que celebraram contratos, receberam
          pagamentos ou que ofertaram proposta com a <b>{nome}</b> podem ser
		  divididas nos seguintes padrões de risco:</p>;
    }

	  return <p>Não foram identificadas empresas de alto risco que celebram contratos, receberam pagamentos ou ofertaram proposta com a <b>{nome}</b></p>;
  };

  return (
    <>
      <h3 className="h4 font-weight-bold">{nome}</h3>
      <Container fluid className="mt-4">
        <AgencyDetailHeaderEl header={cabecalho} />
      </Container>
      {
        resumo &&
        <Container fluid className="px-4 pt-4 pb-2 bg-white mt-4">
          <h3 className="h5 font-weight-semibold mb-3">Resultados</h3>
          {getResultMessage()}
          <Container fluid className="p-0">
            <Row className="no-gutters">
              {resumo.map(({ n, texto, id }) => (
                <div key={texto} className="col-12 col-lg-6 mb-3">
                  <a
                    href={`#${id}`}
                    className="badge badge-primary p-2 px-4 mb-2 mr-2 rounded"
                  >
                    <span className="h6 m-0 font-weight-light">
                      <b>{n}</b> empresa{n > 1 ? "s" : null}
                    </span>
                  </a>{" "}
                  {texto}
                </div>
              ))}
            </Row>
          </Container>
        </Container>
      }
      
      {renderedElements}
    </>
  );
}

export default AgencyDetailTemplate;
