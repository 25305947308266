import { FilterGroup } from "../../../interfaces/models/FilterInterface";
import { LOGIN_LOGOFF } from "../Login/constants";
import { CompaniesHighRiskResponse } from "../../../services/companiesHighRisk/companiesHighRiskApi";

export const COMPANIES_HIGH_RISK_LOADING = "COMPANIES_HIGH_RISK_LOADING";
export const COMPANIES_HIGH_RISK_SUCCESS = "COMPANIES_HIGH_RISK_SUCCESS";
export const COMPANIES_HIGH_RISK_ERROR = "COMPANIES_HIGH_RISK_ERROR";

export interface CompaniesHighRiskState {
  loading: boolean;
  loaded: boolean;
  data?: CompaniesHighRiskResponse;
  error?: string;
}

export const initialState: CompaniesHighRiskState = {
  loading: false,
  loaded: false,
};

export type Action = {
  type: string;
  payload?: CompaniesHighRiskResponse;
  error?: string;
};

export default function CompaniesHighRiskReducer(
  state = initialState,
  action: Action
): CompaniesHighRiskState {
  switch (action.type) {
    case COMPANIES_HIGH_RISK_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case COMPANIES_HIGH_RISK_SUCCESS:
      return {
        ...initialState,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    case COMPANIES_HIGH_RISK_ERROR:
      return {
        ...initialState,
        loaded: true,
        error: action.error,
      };
    case LOGIN_LOGOFF:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
