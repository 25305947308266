type ExternalLinksProps = {
	id: string;
	name: string;
	icon: string | null;
	description: string;
}

const externalLinks: ExternalLinksProps[] = [
	{
		id: `http://54.243.43.93/index.html#conflicts.gexf`,
		name: 'Rede de conflito de interesse de políticos',
		// icon: "home",
		icon: null,
		description: 'Esta rede analisa vínculos entre empresas de políticos contratadas no município ou estado onde foram eleitos',
	},
	{
		id: `https://youtu.be/P5EKc84a9zw`,
		name: 'Tutorial - Busca pelo nome da empresa ou CNPJ',
		description: '',
		// icon: "home",
		icon: null,
	},
	{
		id: `https://youtu.be/F8UoLq7wVqg`,
		name: 'Tutorial - Página de beneficiários de programa social',
		description: '',
		// icon: "home",
		icon: null,
	},
        {
                id: `https://youtu.be/rGbDZto2Dhw`,
                name: 'Tutorial - Página de filtros de empresas',
                description: '',
                // icon: "home",
                icon: null,
        },
        {
                id: `https://youtu.be/ykj0dVkBZmU`,
                name: 'Tutorial - Página de filtros de lotes',
                description: '',
                // icon: "home",
                icon: null,
        },
        {
                id: `https://youtu.be/Su1Yfu4eQa8`,
                name: 'Tutorial - Hipertipologia e PDMs incongruentes',
                description: '',
                // icon: "home",
                icon: null,
        },
        {
                id: `https://youtu.be/jURkgjdOtqo`,
                name: 'Caso de risco #1 - Yeshua Ltda',
                description: '',
                // icon: "home",
                icon: null,
        },
	{
		id: `https://youtu.be/9hZjCm6gyOM`,
		name: 'Caso de risco #2 - Santa Fe Ltda',
		description: 'Explicação resumida',
		// icon: "home",
		icon: null,
	},
	{
		id: `https://youtu.be/EDyFCawIQKM`,
		name: 'Caso de risco #2 - Santa Fe Ltda',
		description: 'Explicação detalhada',
		// icon: "home",
		icon: null,
	},
];


export default externalLinks