import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const SidebarLogoContainer = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center p-3",
})``;

export const SidebarButton = styled.button.attrs({
  className:
    "rounded-circle bg-white border-0 d-flex align-items-center p-0 justify-content-center position-absolute",
})`
  width: 26px;
  height: 26px;
  appearance: none;
  right: -13px;
  top: 66px;
  cursor: pointer;
  z-index: 100;
`;
