import { CommonTable } from "../interfaces/APIInterface";
import { AuthToken } from "../interfaces/AuthInterface";
import { AgencyDetailResponse } from "../interfaces/models/AgencyDetail";
import { AgencyResumeRow } from "../interfaces/models/AgencyResume";
import { BeneficiariesResume } from "../interfaces/models/BeneficiariesResume";
import { BeneficiaryDetail } from "../interfaces/models/BeneficiaryDetail";
import { BiddingResponse } from "../interfaces/models/Bidding";
import {
  CompaniesResume,
  CompaniesResumeResponse,
} from "../interfaces/models/CompaniesResume";
import { CompanyDetailResponse } from "../interfaces/models/CompanyDetail";
import { FAQItem } from "../interfaces/models/FAQInterface";
import { FilterGroup } from "../interfaces/models/FilterInterface";
import { FilterResultResponse } from "../interfaces/models/FilterResultResponse";
import { PoliticianDetailResponse } from "../interfaces/models/PoliticianDetail";
import { PoliticiansResume } from "../interfaces/models/PoliticiansResume";
import { PrintRiskThemeItem } from "../interfaces/models/PrintRiskThemeItem";
import { PrintRiskThemeItemDetail } from "../interfaces/models/PrintRiskThemeItemDetail";
import { PrintThemeDashData } from "../interfaces/models/PrintThemeDashData";
import { RiskThemeItem } from "../interfaces/models/RiskThemeItem";
import { RiskThemeItemCriteria } from "../interfaces/models/RiskThemeItemCriteria";
import { RiskThemeItemDetail } from "../interfaces/models/RiskThemeItemDetail";
import { ThemeDashData } from "../interfaces/models/ThemeDashData";
import { LoginResponse } from "../interfaces/ServerInterface";

const prefix = "/api";
const baseURL = "http://api:4242";
  

function getDefaultHeader(method: string, auth: string) {
  return {
    method: method,
    headers: {
      Authorization: "Token " + auth,
    },
  };
}

export function API(endpoint: string, options?: RequestInit) {
  if (process.env.NODE_ENV === "development") {
    return fetch(`${baseURL}/${endpoint}`, options)
      .then((s) => (s.ok ? s : Promise.reject(s.statusText)))
      .then((s) => s.json());
  }

  return fetch(`${prefix}/${endpoint}`, options)
    .then((s) => (s.ok ? s : Promise.reject(s.statusText)))
    .then((s) => s.json());
}

export async function LoginAPI(
  user: string,
  password: string
): Promise<LoginResponse> {
  try {
    return await API("login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ user, password }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetFiltersAPI(auth: AuthToken): Promise<FilterGroup[]> {
  try {
    const res: { filtros: FilterGroup[] } = await API("filtros", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth }),
    });
    return res.filtros;
  } catch (error) {
    throw new Error(error);
  }
}

export async function FilterAPI(
  auth: AuthToken,
  input: {
    [a: string]: any;
  }
): Promise<FilterResultResponse> {
  try {
    return await API("filtrar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, input }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function CompanyDetailAPI(
  auth: AuthToken,
  cnpj: string
): Promise<CompanyDetailResponse> {
  try {
    return await API("empresa", {
      method: "post",
      headers: {
        Connection: "Keep-Alive",
        "Keep-Alive": "timeout=5, max=1000",
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, cnpj }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function CompanyDetailReportAPI(
  auth: AuthToken,
  cnpj: string
): Promise<CompanyDetailResponse> {
  try {
    return await API(`empresa/${cnpj}/report`, {
      method: "get",
      headers: {
        Connection: "Keep-Alive",
        "Keep-Alive": "timeout=5, max=1000",
        Authorization: "Token " + auth.auth,
      },
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function CompaniesSearchAPI(
  auth: AuthToken,
  termo: string
): Promise<CompaniesResume> {
  try {
    const res: CompaniesResumeResponse = await API("buscar_empresa", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, termo }),
    });

    return res.empresas;
  } catch (error) {
    throw new Error(error);
  }
}

export async function PublicAgenciesAPI(
  auth: AuthToken
): Promise<CommonTable<AgencyResumeRow>> {
  try {
    const res: { orgaos_publicos: CommonTable<AgencyResumeRow> } = await API(
      "orgaos_publicos",
      {
        method: "post",
        headers: {
          Authorization: "Token " + auth.auth,
        },
        body: JSON.stringify(auth),
      }
    );

    return res.orgaos_publicos;
  } catch (error) {
    throw new Error(error);
  }
}

export async function PublicAgencyAPI(
  auth: AuthToken,
  id: string
): Promise<AgencyDetailResponse> {
  try {
    const publicAgency: AgencyDetailResponse = await API("orgao_publico", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({
        auth: auth.auth,
        id: parseInt(id, 10),
      }),
    });

    return publicAgency;
  } catch (error) {
    throw new Error(error);
  }
}

export async function SuperiorAgenciesAPI(
  auth: AuthToken
): Promise<CommonTable<AgencyResumeRow>> {
  try {
    const res: { orgaos_superiores: CommonTable<AgencyResumeRow> } = await API(
      "orgaos_superiores",
      {
        method: "post",
        headers: {
          Authorization: "Token " + auth.auth,
        },
        body: JSON.stringify(auth),
      }
    );

    const {
      orgaos_superiores: { colunas, linhas, Title, descricao },
    } = res;

    return {
      Title,
      descricao,
      colunas,
      linhas: linhas.sort(
        ({ nome_entidade: nameA }, { nome_entidade: nameB }) => {
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }
      ),
    };
  } catch (error) {
    throw new Error(error);
  }
}

export async function SuperiorAgencyAPI(
  auth: AuthToken,
  id: string
): Promise<AgencyDetailResponse> {
  try {
    const superiorAgency: AgencyDetailResponse = await API("orgao_superior", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({
        auth: auth.auth,
        id: parseInt(id, 10),
      }),
    });

    return superiorAgency;
  } catch (error) {
    throw new Error(error);
  }
}

export async function BiddingAPI(
  auth: AuthToken,
  id_lote: string
): Promise<BiddingResponse> {
  try {
    return await API("lote", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, id_lote }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function PoliticiansAPI(
  auth: AuthToken
): Promise<PoliticiansResume> {
  try {
    const res: { politicos: PoliticiansResume } = await API("politicos", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify(auth),
    });

    return res.politicos;
  } catch (error) {
    throw new Error(error);
  }
}

export async function PoliticianDetailAPI(
  auth: AuthToken,
  id: number
): Promise<PoliticianDetailResponse> {
  try {
    return await API("politico", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, id_politico: id }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function BeneficiariesAPI(
  auth: AuthToken
): Promise<BeneficiariesResume> {
  try {
    const res: { beneficiarios: BeneficiariesResume } = await API(
      "beneficiarios",
      {
        method: "post",
        headers: {
          Authorization: "Token " + auth.auth,
        },
        body: JSON.stringify(auth),
      }
    );

    return res.beneficiarios;
  } catch (error) {
    throw new Error(error);
  }
}

export async function BeneficiaryDetailAPI(
  auth: AuthToken,
  id: string
): Promise<BeneficiaryDetail> {
  try {
    return await API("beneficiario", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, id_beneficiario: id }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function FaqAPI(auth: AuthToken): Promise<FAQItem[]> {
  try {
    const res: { faq: FAQItem[] } = await API("faq", {
      method: "post",
      headers: {
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth }),
    });
    return res.faq;
  } catch (error) {
    throw new Error(error);
  }
}
export async function RiskThemeDashAPI(
  auth: AuthToken,
  uf: string
): Promise<ThemeDashData> {
  try {
    return await API(`risk-theme/header/${uf}`, {
      method: "GET",
      headers: {
        Authorization: "Token " + auth.auth,
      },
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function RiskThemeListAPI(
  auth: AuthToken,
  uf: string
): Promise<RiskThemeItem[]> {
  try {
    const res: { table: RiskThemeItem[] } = await API(
      `risk-theme/table/${uf}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token " + auth.auth,
        },
      }
    );
    return res.table;
  } catch (error) {
    throw new Error(error);
  }
}

export async function RiskThemeDetailAPI(
  auth: AuthToken,
  uf: string,
  id_tema: string
): Promise<RiskThemeItemDetail> {
  try {
    return await API(
      `risk-theme/details/${uf}/${id_tema}`,
      getDefaultHeader("GET", auth.auth)
    );
  } catch (error) {
    throw new Error(error);
  }
}

export async function RiskThemeCriteriaAPI(
  auth: AuthToken,
  uf: string,
  id_tema: string
): Promise<RiskThemeItemCriteria> {
  try {
    return await API(
      `risk-theme/criterias/${uf}/${id_tema}`,
      getDefaultHeader("GET", auth.auth)
    );
  } catch (error) {
    throw new Error(error);
  }
}

export async function PrintRiskThemeDashAPI(
  auth: AuthToken,
  uf: string
): Promise<PrintThemeDashData> {
  try {
    return await API(`risk-theme/resume-header/${uf}`, {
      method: "GET",
      headers: {
        Authorization: "Token " + auth.auth,
      },
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function PrintRiskThemeListAPI(
  auth: AuthToken,
  uf: string
): Promise<PrintRiskThemeItem[]> {
  try {
    const res: { table: PrintRiskThemeItem[] } = await API(
      `risk-theme/resume-table/${uf}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token " + auth.auth,
        },
      }
    );
    return res.table;
  } catch (error) {
    throw new Error(error);
  }
}

export async function RiskThemeCompaniesByUFAPI(
  auth: AuthToken,
  uf: string,
  id_tema: string
): Promise<PrintRiskThemeItemDetail> {
  try {
    return await API(
      `risk-theme/companies/${uf}/${id_tema}`,
      getDefaultHeader("GET", auth.auth)
    );
  } catch (error) {
    throw new Error(error);
  }
}

export async function ChangeBaseAPI(
  auth: AuthToken,
  id_base: number
) {
  try {
    return await API("change_user_id_base", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + auth.auth
      },
      body: JSON.stringify({ "id_base": id_base }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetBaseAPI(
  auth: AuthToken,
) {
  try {
	  console.log("chegou")
    return await API("base", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + auth.auth
      },
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function ChangePasswordAPI(
  auth: AuthToken,
  password: string
) {
  try {
    return await API("mudar_senha/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + auth.auth
      },
      body: JSON.stringify({ "nova_senha": password }),
    });
  } catch (error) {
    throw new Error(error);
  }
}
