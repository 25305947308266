import { FilterGroup } from "../../../interfaces/models/FilterInterface";
import { LOGIN_LOGOFF } from "../Login/constants";

export const BIDDING_FILTERS_LOADING = "BIDDING_FILTERS_LOADING";
export const BIDDING_FILTERS_SUCCESS = "BIDDING_FILTERS_SUCCESS";
export const BIDDING_FILTERS_ERROR = "BIDDING_FILTERS_ERROR";

export interface BiddingFiltersState {
  loading: boolean;
  loaded: boolean;
  data?: FilterGroup[];
  error?: string;
}

export const initialState: BiddingFiltersState = {
  loading: false,
  loaded: false,
};

export type Action = {
  type: string;
  payload?: FilterGroup[];
  error?: string;
};

export default function BiddingFiltersReducer(
  state = initialState,
  action: Action
): BiddingFiltersState {
  switch (action.type) {
    case BIDDING_FILTERS_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case BIDDING_FILTERS_SUCCESS:
      return {
        ...initialState,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    case BIDDING_FILTERS_ERROR:
      return {
        ...initialState,
        loaded: true,
        error: action.error,
      };
    case LOGIN_LOGOFF:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
