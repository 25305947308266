import React, { useMemo } from "react";

import { BeneficiaryDetail } from "../../../interfaces/models/BeneficiaryDetail";
import WarningHeader from "../../molecules/WarningHeader";
import ContentOrderer from "../../organisms/ContentOrderer/ContentOrderer";

function BeneficiaryDetailTemplate({
	company: { nome, tabelas, cabecalho },
}: {
	company: BeneficiaryDetail;
}) {
	const renderedElements = useMemo(
		() =>
			ContentOrderer({
				tables: tabelas,
			}),
		[tabelas]
	);

	return (
		<>
			<h3 className="h4 font-weight-bold pb-3">{nome}</h3>
			<WarningHeader content={cabecalho} />
			{renderedElements}
		</>
	);
}

export default BeneficiaryDetailTemplate;
