import React, { useEffect, useMemo, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useField } from "formik";

import {
  InputInterface,
  SelectValue,
  SliderOptions,
} from "../../../interfaces/models/FilterInterface";
import BooleanInput from "./BooleanInput";
import RangeInput from "./RangeInput";

import { Flex } from "rebass";
import { Tooltip } from "../../../ui";

const findValueFromOptions = (options: SelectValue[], key: any): any => {
  if (options[1] && !options[1].key) return key;

  const found = options.find(
    ({ key: oKey, value }) => oKey === key || value === key
  );

  if (found) return found.value;
  return key;
};

function Input({
  id,
  label,
  type,
  options,
  tooltip,
  setFilterDescription,
}: InputInterface) {
  const [, { value }, { setValue }] = useField({
    name: id,
  });

  const [
    shouldRenderFilterDescription,
    setShouldRenderFilterDescription,
  ] = useState<boolean>(!!setFilterDescription);

  const renderFilterOptionDescription = (value: string) => {
    if (type !== "select") {
      return setFilterDescription("");
    }

    // @ts-ignore
    const filterOptionByValue = options?.find((option) => {
      if (option.value === value) {
        return option;
      }

      // default filter does not have value example: filtro todos -> { default: true
      //  label: "Todos"
      //  message: " Cras posuere nibh sed lorem egestas tincidunt. Nam tristique odio non ante tristique, sed convallis sapien fermentum. Fusce quis neque in ipsum tempor consectetur. Mauris eu maximus turpis. Praesent elementum commodo urna ac tincidunt. Morbi quis dolor vel turpis egestas sagittis iaculis pulvinar tortor. "
      //  value: null
      // }
      // so, validate if label it is equal value, if yes must be the default filter
      if (!option.value) {
        if (option.label === value) {
          return option;
        }
      }

      return null;
    });

    if (!filterOptionByValue) {
      return setFilterDescription("");
    }

    return setFilterDescription(filterOptionByValue.message);
  };

  const handleSelectOnChange = (value: string) => {
    setValue(findValueFromOptions(options as SelectValue[], value));
    shouldRenderFilterDescription && renderFilterOptionDescription(value);
  };

  useEffect(() => {
    shouldRenderFilterDescription && renderFilterOptionDescription(value);
  }, []);

  const input = useMemo(() => {
    switch (type) {
      case "boolean":
        return (
          <Form.Control as={BooleanInput} setValue={setValue} value={value} />
        );
      case "select":
        return (
          <Form.Control
            as="select"
            custom
            onChange={({ target: { value } }) => handleSelectOnChange(value)}
            value={value ?? options[0].key ?? options[0].value}
          >
            {(options as SelectValue[]).map(
              ({ label, key, value: optionValue }) => (
                <option key={key ?? optionValue} value={key ?? optionValue}>
                  {label}
                </option>
              )
            )}
          </Form.Control>
        );
      case "slider":
        return (
          <Form.Control
            as={RangeInput}
            setValue={setValue}
            value={value}
            options={options as SliderOptions}
            id={id}
          />
        );
      default:
        return null;
    }
  }, [value]);

  const renderFilterLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <Form.Label className="d-block mb-2 font-weight-semibold">
        {label}
      </Form.Label>
    );
  };

  const renderTooltip = () => {
    if (!tooltip) {
      return null;
    }

    return <Tooltip tooltip={tooltip} />;
  };

  return (
    <FormGroup id={id} className="mb-4">
      <Flex alignItems={"center"}>
        {renderFilterLabel()}
        {renderTooltip()}
      </Flex>
      {input}
    </FormGroup>
  );
}

export default Input;
