import React from "react";
import RiskFindingCard from "../../components/riskFindingCard/RiskFindingCard";
import { Row, Col } from "react-bootstrap";
import { CompaniesHighRiskResponse } from "../../services/companiesHighRisk/companiesHighRiskApi";

import { Flex, Text } from "rebass";
import styled from "styled-components";

export const ResultHeader = styled(Flex)`
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 5px 10px;
  background-color: ${(props) => props?.backgrounColor || "transparent"};
  margin-bottom: 16px !important;
  color: white;
  border-radius: 3px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

type CompaniesHighRiskCardListProps = {
  companiesHighRisk: CompaniesHighRiskResponse;
};

const CompaniesHighRiskCardList = ({
  companiesHighRisk,
}: CompaniesHighRiskCardListProps) => {
  if (!companiesHighRisk) {
    return null;
  }

  const { results } = companiesHighRisk;
  const { color = "green" } = results;

  const renderCompaniesHighRisk = () => {
    if (!companiesHighRisk) {
      return null;
    }

    const { data } = companiesHighRisk;

    return data.map((d, i) => {
      const link = `/empresa/${d.document}`;

      const {
        superficial_alerts = 0,
        relevant_alerts = 0,
      } = d;

      return (
        <Col key={i} md={3} className={"mb-3"}>
          <RiskFindingCard
            title={d.title}
            subtitle={d.document}
            value={d.value}
            badge={d?.badge}
            progress={superficial_alerts}
            secondProgress={relevant_alerts}
            link={link}
          />
        </Col>
      );
    });
  };

  const renderResultContent = () => {
    const { content } = results;

    return content.map((c, i) => (
      <Flex key={i} flexDirection={"column"} alignItems={"center"}>
        <Text fontSize={"14px"} fontWeight={"bold"}>
          {c.title}
        </Text>
        <Text fontWeight={"bold"} fontSize={"35px"}>
          {c.value}
        </Text>
      </Flex>
    ));
  };

  return (
    <>
      <Row>
        <Col md={12} className={"mb-3"}>
          <ResultHeader backgrounColor={color}>
            {renderResultContent()}
          </ResultHeader>
          <Row>{renderCompaniesHighRisk()}</Row>
        </Col>
      </Row>
    </>
  );
};

export default CompaniesHighRiskCardList;
