import React, { useMemo } from "react";
import { Container } from "react-bootstrap";
import cls from "classnames";

import { CommonGraphContainer } from "../../../interfaces/models/CommonTypes";
import BarGraph from "../BarGraph/BarGraph";
import MultipleBarGraph from "../MultipleBarGraph/MultipleBarGraph";
import { renderDescription } from "../../organisms/ContentOrderer/ContentOrderer";

function GraphBlock({
  graph: { tipo, titulo, dados, descricao, conteudo, hideXAxisLabel },
}: {
  graph: CommonGraphContainer;
}) {
  const graph = useMemo(() => {
    switch (tipo) {
      case "bar":
        return dados && (dados as any[]).length ? (
          <BarGraph
            data={dados as any[]}
            content={conteudo}
            hideXAxisLabel={hideXAxisLabel}
          />
        ) : undefined;
      case "multibar":
        return (
          <MultipleBarGraph
            data={dados as any}
            hideXAxisLabel={hideXAxisLabel}
          />
        );
      default:
        return null;
    }
  }, [dados, conteudo, hideXAxisLabel]);

  const titleClass = cls("h5 font-weight-bold", {
    "mb-2": descricao,
    "mb-4": !descricao,
  });

  return (
    <Container
      fluid
      className="bg-white p-4 mt-4 rounded print-no-padding"
      key={titulo}
    >
      <h4 className={titleClass}>{titulo}</h4>
      {renderDescription(descricao, descricao ? "mb-4" : "")}
      {graph}
    </Container>
  );
}

export default GraphBlock;
