import React from "react";
// eslint-disable-next-line import/named
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeProvider } from "styled-components";

import { AgencyReportColor } from "../../../interfaces/models/AgencyDetail";
import {
	AgencyHeaderTheme,
	Background,
	Description,
	IconBg,
} from "./AgencyHeader.styles";

interface AgencyHeaderInterface {
	icon: string;
	color: AgencyReportColor;
	text: string;
	description: string;
}

function AgencyHeader({
	color,
	description,
	icon,
	text,
}: AgencyHeaderInterface) {
	const theme: AgencyHeaderTheme = { color };
	return (
		<ThemeProvider theme={theme}>
			<Background>
				<div className="h-100 d-flex flex-col align-items-center justify-content-center h4 m-0">
					<IconBg>
						<FontAwesomeIcon icon={icon as IconName} />
					</IconBg>
				</div>
				<div className="pl-3">
					<p className="h1 font-weight-bold mb-1 word-wrap-anywhere">{text}</p>
					<Description>{description}</Description>
				</div>
			</Background>
		</ThemeProvider>
	);
}

export default AgencyHeader;
