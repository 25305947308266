import { CommonTable } from "../../../interfaces/APIInterface";
import { AgencyResumeRow } from "../../../interfaces/models/AgencyResume";
import { LOGIN_LOGOFF } from "../Login/constants";
import {
	PUBLIC_AGENCIES_ERROR,
	PUBLIC_AGENCIES_LOADING,
	PUBLIC_AGENCIES_SUCCESS,
} from "./constants";

export interface SuperiorAgenciesState {
	loading: boolean;
	loaded: boolean;
	data?: CommonTable<AgencyResumeRow>;
	error?: string;
}

const initialState: SuperiorAgenciesState = {
	loading: false,
	loaded: false,
};

type Action = {
	type: string;
	payload?: CommonTable<AgencyResumeRow>;
	error?: string;
};

export default function SuperiorAgenciesReducer(
	state = initialState,
	action: Action
): SuperiorAgenciesState {
	switch (action.type) {
		case PUBLIC_AGENCIES_LOADING:
			return {
				...initialState,
				loading: true,
			};
		case PUBLIC_AGENCIES_SUCCESS:
			return {
				...initialState,
				loading: false,
				loaded: true,
				data: action.payload,
			};
		case PUBLIC_AGENCIES_ERROR:
			return {
				...initialState,
				loaded: true,
				error: action.error,
			};
		case LOGIN_LOGOFF:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
