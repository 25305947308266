import { Dispatch } from "react";
import { AuthToken } from "../../interfaces/AuthInterface";
import { FilterGroup } from "../../interfaces/models/FilterInterface";
import { API } from "../api";
import { SearchAction } from "../../components/reducers/SearchReducer";
import { BiddingFilterResultResponse } from "../../pages/bidding/biddingFilter/BiddingFilterResult";

export const GetBiddingFiltersAPI = async (
  auth: AuthToken
): Promise<FilterGroup[]> => {
  try {
    const res: { filtros: FilterGroup[] } = await API("filtros_lotes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth }),
    });
    return res.filtros;
  } catch (error) {
    throw new Error(error);
  }
};

export async function FilterBiddingFiltersAPI(
  auth: AuthToken,
  input: {
    [a: string]: any;
  }
): Promise<BiddingFilterResultResponse> {
  try {
    return await API("filtrar_lotes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + auth.auth,
      },
      body: JSON.stringify({ ...auth, input }),
    });
  } catch (error) {
    throw new Error(error);
  }
}

export const sendFilterBidding = (
  auth: AuthToken,
  dispatch: Dispatch<SearchAction<BiddingFilterResultResponse>>
) => async (filter: any) => {
  dispatch({ type: "loading" });
  try {
    const result = await FilterBiddingFiltersAPI(auth, filter?.input ?? {});
    dispatch({ type: "loaded", result });
  } catch (error) {
    dispatch({ type: "error", error });
  }
};
