import React, { useState } from "react";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { Container, Button, Form } from "react-bootstrap";
import { useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";

// import logo from "../assets/images/logo.svg";
import { logoff, change_password } from "../store/actions/LoginActions";
import { RootReducers } from "../store/reducers";
import useConnectedSelector from "../utils/useConnectedSelector";

const schema = yup.object({
	password: yup.string().required(),
  repeat_password: yup.string().oneOf([yup.ref('password')], 'Senhas precisam ser iguais').required(),
});

const redirection = "/login";

const Gradient = styled.div`
	background: linear-gradient(90deg, #394e63 -8.05%, #263544 100%);
`

const Divider = styled.div`
	background: rgb(225, 229, 237);
	height: 4px;
	width: 100%;
`;

const renderError = (error: Error): string => {
	console.dir(error);
	switch (error.message) {
		case "Unauthorized":
			return "Usuário e/ou senha incorretos";
		default:
			return error.message;
	}
};

function Index() {
	const { dispatch } = useStore();
	const history = useHistory();
	const [error, setError] = useState<Error>();
	const { loading, data: loginData } = useSelector(
		(state: RootReducers) => state.LoginReducer
	);
  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);

	const onSubmit = async ({
		password,
		repeat_password,
	}: {
		password: string;
		repeat_password: string;
	}) => {
		if (error) {
			setError(undefined);
		}
		try {
			await change_password(auth, password)(dispatch);
      logoff(dispatch);
			history.push(redirection);
		} catch (e) {
			setError(e);
		}
	};

	return (
    <MainTemplate withoutContainer>
      <Container fluid className="p-0">
				<div className="px-5 py-4 pb-5 bg-white rounded-bottom">
					<h3 className="text-center" style={{ color: '#808080' }}>Altere a sua senha aqui</h3>
					<p className="text-center" style={{ color: '#808080' }}>Após alterar a senha você será deslogado da aplicação</p>
					<Formik
						validationSchema={schema}
						onSubmit={onSubmit}
						initialValues={{ password: "", repeat_password: "" }}
					>
						{({ handleSubmit, handleChange, values }) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group controlId="password">
									<Form.Label className="h7 font-weight-semibold">
                  Nova Senha
									</Form.Label>
									<Form.Control
										required
										type="text"
										value={values.password}
										onChange={handleChange}
										placeholder="Digite a nova senha"
									/>
								</Form.Group>
								<Form.Group controlId="repeat_password">
									<Form.Label className="h7 font-weight-semibold">
										Repita a Nova Senha
									</Form.Label>
									<Form.Control
										required
										type="text"
										value={values.repeat_password}
										onChange={handleChange}
										placeholder="Repita a senha acima"
									/>
								</Form.Group>
								{loading && (
									<p className="m-0 mt-4 text-secondary">Carregando</p>
								)}
								{error && (
									<p className="m-0 mt-4 text-danger">{renderError(error)}</p>
								)}

								<div className="d-flex justify-content-center pt-4">
									<Button
										className="gradient-button h8 py-2 px-5 h8"
										type="submit"
									>
										Alterar senha
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
      </Container>
    </MainTemplate>
	);
}

export default Index;
