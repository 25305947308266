import React, { Dispatch, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import AgencyDetailTemplate from "../components/template/AgencyDetailTemplate/AgencyDetailTemplate";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { AuthToken } from "../interfaces/AuthInterface";
import { AgencyDetailResponse } from "../interfaces/models/AgencyDetail";
import { SuperiorAgencyAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<AgencyDetailResponse>();

const getSuperiorAgency = (auth: AuthToken, id: string) => async (
  dispatch: Dispatch<SearchAction<AgencyDetailResponse>>
) => {
  dispatch({ type: "loading" });
  try {
    const result = await SuperiorAgencyAPI(auth, id);
    dispatch({ type: "loaded", result });
  } catch (e) {
    dispatch({ type: "error", error: e });
  }
};

export default function SuperiorAgencyDetail() {
  const [{ loading, result, error }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);
  const { id } = useParams<any>();

  useEffect(() => {
    getSuperiorAgency(auth, id)(dispatch);
  }, []);

  return (
    <MainTemplate title="Detalhe de Órgão Superior" withoutContainer>
      <LoadingError loading={loading} error={error} />
      {result ? <AgencyDetailTemplate agency={result} /> : null}
    </MainTemplate>
  );
}
