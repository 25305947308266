import React from "react";
import { Card, Container } from "react-bootstrap";
import { CardsData } from "../../../interfaces/models/CommonTypes";
import formatValue from "../../../utils/formatValue";

interface CardProps {
  card: CardsData;
}

const cardProps = {
  flex: "none",
};

function CardBlock(props: CardProps) {
  const cardsData = props.card;

  return (
    <Container
      fluid
      className="bg-white p-4 mt-4 rounded avoid-page-break print-no-padding"
      key={cardsData.titulo}
    >
      <h4 className="h5 font-weight-bold">{cardsData.titulo}</h4>
      <p>{cardsData.descricao}</p>
      <div className="d-flex gap-1 flex-nowrap">
        {cardsData.items.map((item, index) => (
          <Card
            key={`cards-item-${index}`}
            className="bg-light p-3 w-100 d-flex flex-column justify-content-between"
          >
            <h6>{item.titulo}</h6>
            <Card.Body className="p-0" style={cardProps}>
              {formatValue(item.valor, item.render)}
            </Card.Body>
          </Card>
        ))}
      </div>
    </Container>
  );
}

export default CardBlock;
