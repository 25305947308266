/* eslint-disable react/display-name */
import React, { Dispatch, useMemo, useReducer } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { AuthToken } from "../interfaces/AuthInterface";
import useConnectedSelector from "../utils/useConnectedSelector";
import {
  CompaniesTableSearchAPI,
  CompaniesTableSearchTabela,
} from "../services/companies/companiesApi";
import ContentOrderer from "../components/organisms/ContentOrderer/ContentOrderer";

interface CompaniesSearch {
  tables: CompaniesTableSearchTabela[];
  searchText: string;
}

const [initialState, reducer] = SearchReducer<CompaniesSearch>();

const searchCompany = (
  auth: AuthToken,
  dispatch: Dispatch<SearchAction<CompaniesSearch>>
) => async ({ company }) => {
  dispatch({ type: "loading" });
  try {
    const result = await CompaniesTableSearchAPI(auth, company);
    dispatch({
      type: "loaded",
      result: {
        searchText: company,
        tables: result,
      },
    });
  } catch (error) {
    dispatch({ type: "error", error });
  }
  return false;
};

const scheme = yup.object({
  company: yup
    .string()
    .required("Nome da empresa é necessário")
    .min(3, "O nome da empresa tem que ter pelo menos 3 caracteres"),
});

function Companies() {
  const [{ loading, error, result }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);

  const onSearch = useMemo(() => searchCompany(auth, dispatch), [auth]);

  const renderedElements = () =>
    ContentOrderer({
      tables: result?.tables,
      graphs: null,
      map: null,
      iframes: null,
    });

  return (
    <MainTemplate title="Consultar Empresas" withoutContainer>
      <Container fluid className="bg-white mt-4 p-4 rounded">
        <Formik
          validationSchema={scheme}
          onSubmit={onSearch}
          initialValues={{ company: "" }}
        >
          {({ handleSubmit, handleChange, errors, values }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group controlId="company">
                <Form.Label className="h5 font-weight-bold mb-4">
                  Pesquise pelo sistema
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pesquise..."
                  value={values.company}
                  onChange={handleChange}
                  isInvalid={!!errors.company}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.company}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={loading}>
                Pesquisar
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
      <LoadingError loading={loading} error={error} />
      {result && renderedElements()}
    </MainTemplate>
  );
}

export default Companies;
