import React from "react";

import { colors } from "../../styles/colors";
import styled from "styled-components";

const { greyProgress } = colors;

export const Container = styled.div<{width: number | null; height: number | null}>`
  background: ${greyProgress};
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  max-width: ${({ width }) => (width ? `${width}px` : "500px")};
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "30px")};
`;

export const Bar = styled.div<{height: string | number | null; progress: string | null}>`
  border-radius: 100px;
  background: ${({ color }) => color};
  height: ${({ height }) => (height ? `${height}px` : "30px")};
  width: ${({ progress }) => progress};
  position: relative;
  z-index: 2;
`;

export const SecondBar = styled.div<{height: string | number | null; secondProgress: string}>`
  border-radius: 0px 100px 100px 0px;
  background: ${({ color }) => color};
  height: ${({ height }) => (height ? `${height}px` : "30px")};
  width: ${({ secondProgress }) => secondProgress};
  position: relative;
  z-index: 1;
  box-shadow: -20px 0px 0px 0px ${({ color }) => color};
`;

type ProgressBarProps = {
  progress: string;
  secondProgress: string;
  color: string;
  secondColor: string;
  height: number | null;
  width: number | null;
};

const ProgressBar = ({
  progress,
  color,
  secondProgress,
  secondColor,
  height,
  width,
}: ProgressBarProps) => {
  return (
    <Container width={width} height={height}>
      <Bar height={height} progress={progress} color={color} />
      {secondProgress && (
        <SecondBar
          height={height}
          secondProgress={secondProgress}
          color={secondColor}
        />
      )}
    </Container>
  );
};

export default ProgressBar;
