import React, { useState } from "react";
import { FilterGroup } from "../../interfaces/models/FilterInterface";
import Input from "../../components/molecules/FormItems/Input";

import { Accordion, Card, Col, Row } from "react-bootstrap";

import { Flex } from "rebass";

const FilterFormRowAccordionFormik = ({
  filter: { title, inputs },
  index,
}: {
  filter: FilterGroup;
  index: number;
}) => {
  const [isActive, setActive] = useState(index === 0);

  return (
    <Card className="border-top-0 border-right-0 border-left-0 border-bottom">
      <Accordion.Toggle
        as={Card.Header}
        className="bg-white h5 font-weight-bold pl-0 pt-4 d-flex w-100 justify-content-between"
        onClick={() => setActive(!isActive)}
        eventKey={index.toString()}
      >
        <Flex flexDirection={"column"}>
          <Flex>
            <span>{title}</span>
            <span
              className="font-weight-light"
              style={{ transform: isActive ? "rotate(180deg)" : "" }}
            >
              ⌄
            </span>
          </Flex>
        </Flex>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body className="px-0">
          <Row>
            {inputs.map((input) => (
              <Col lg={6} key={input.id} className="mb-3 mb-lg-0">
                <Input {...input} />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default FilterFormRowAccordionFormik;
