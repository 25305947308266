import React, { useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Formik } from "formik";

import { FilterGroup } from "../../../interfaces/models/FilterInterface";
import Input from "../../molecules/FormItems/Input";

interface FilterFormProps {
  sendFilter: (event: React.FormEvent<HTMLFormElement>) => void;
  filters: FilterGroup[];
  loading: boolean;
  filterLabel: string | null | undefined;
}

type FilterRowProps = {
  filter: FilterGroup;
  index: number;
};


export function FilterRow({
  filter: { title, inputs },
  index,
}: FilterRowProps) {
  const [isActive, setActive] = useState(index === 0);

  return (
    <Card className="border-top-0 border-right-0 border-left-0 border-bottom">
      <Accordion.Toggle
        as={Card.Header}
        className="bg-white h5 font-weight-bold pl-0 pt-4 d-flex w-100 justify-content-between"
        onClick={() => setActive(!isActive)}
        eventKey={index.toString()}
      >
        <span>{title}</span>
        <span
          className="font-weight-light"
          style={{ transform: isActive ? "rotate(180deg)" : "" }}
        >
          ⌄
        </span>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body className="px-0">
          <Row>
            {inputs.map((input) => (
              <Col lg={6} key={input.id} className="mb-3 mb-lg-0">
                <Input {...input} />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

const FilterForm = ({ sendFilter, filters, loading, filterLabel = 'Filtrar Empresas' }: FilterFormProps) => {
  return (
    <Formik initialValues={{} as any} onSubmit={sendFilter}>
      {({ handleSubmit }) => (
        <>
          <Form className="bg-white mb-lg-0" onSubmit={handleSubmit} noValidate>
            <Accordion defaultActiveKey="0">
              {filters?.map((filter, index) => (
                <FilterRow key={filter.title} filter={filter} index={index} />
              ))}
            </Accordion>
            <div className="pt-5">
              <Button
                type="submit"
                className="gradient-button h8 py-2 px-4"
                disabled={loading}
              >
                {!loading ? filterLabel : (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="pl-2">Carregando...</span>
                  </>
                )}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default FilterForm;
