/* eslint-disable react/display-name */
import React from "react";
import { Container } from "react-bootstrap";
import MaterialTable from "../components/atoms/MaterialTable/MaterialTable";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import useConnectedSelector from "../utils/useConnectedSelector";

function SuperiorAgencies() {
	
	const superiorAgencies = useConnectedSelector(
		({ SuperiorAgenciesReducer }) => SuperiorAgenciesReducer
	)

	if(superiorAgencies.data){
		const { colunas, linhas, searchable, Title, descricao } = superiorAgencies.data;
		return (
			<MainTemplate title="Consultar órgão superior">
				{Title && <h3 className="h5 font-weight-bold">{Title}</h3>}
				{descricao && <p className="mt-4">{descricao}</p>}
				<MaterialTable
					columnWithOptions={colunas}
					data={linhas}
					noShadow
					searchLeft={searchable !== false}
					sticky
					options={{
						paging: false,
					}}
				/>
			</MainTemplate>
		);
	} else{
		return(
			<MainTemplate title="Consultar órgão superior">
			<Container fluid className="bg-white p-4 mt-4">
				<p>Página em manutenção.</p>
				</Container>
			</MainTemplate>
		)
	}
}

export default SuperiorAgencies;
