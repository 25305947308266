import React from "react";
import { FilterGroup } from "../../interfaces/models/FilterInterface";
import { AuthToken } from "../../interfaces/AuthInterface";
import { Dispatch } from "redux";
import { useStore } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import useConnectedSelector from "../../utils/useConnectedSelector";

import { Button as _Button, ButtonProps, Spinner, Accordion } from "react-bootstrap";
import styled from "styled-components";
import FilterFormRowFormik from "./FilterFormRowFormik";
import FilterFormRowAccordionFormik from "./FilterFormRowAccordionFormik";

const Button = styled(_Button)<{ onClick: React.FormEvent } & ButtonProps>`width: 190px`;

type FilterFormFormikProps = {
  sendFilter: (auth: AuthToken, dispatch: Dispatch) => any;
  filters: FilterGroup[];
  loading: boolean;
  filterLabel: string | null | undefined;
  renderAccordion: boolean;
};

const FilterFormFormik = ({
  sendFilter,
  filters,
  loading,
  filterLabel = "Filtrar Empresas",
  renderAccordion = false,
}: FilterFormFormikProps) => {
  const { dispatch } = useStore();

  const { auth } = useConnectedSelector(({ LoginReducer }) => {
    return {
      auth: LoginReducer.data,
    };
  });

  const onSubmit = async (values) => {
    await sendFilter(auth, dispatch)(values);
  };

  const formikbag = useFormik({
    initialValues: {},
    onSubmit,
  });

  const { handleSubmit } = formikbag;

  const renderFilters = () => {
    if (renderAccordion) {
      return (
        <Accordion defaultActiveKey="0">
          {filters?.map((filter, index) => (
            <FilterFormRowAccordionFormik
              key={filter.title}
              filter={filter}
              index={index}
            />
          ))}
        </Accordion>
      );
    }

    return filters?.map((filter, index) => (
      <FilterFormRowFormik key={filter.title} filter={filter} index={index} />
    ));
  };

  return (
    <FormikProvider value={formikbag}>
      <>
        {renderFilters()}
        <div className="pt-3">
          <Button
            className="gradient-button h8 py-2 px-4"
            disabled={loading}
            onClick={handleSubmit}
          >
            {!loading ? (
              filterLabel
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="pl-2">Carregando...</span>
              </>
            )}
          </Button>
        </div>
      </>
    </FormikProvider>
  );
};

export default FilterFormFormik;
