import { Dispatch } from "redux";

import { AuthToken } from "../../../interfaces/AuthInterface";
import { FilterGroup } from "../../../interfaces/models/FilterInterface";

import {
  BIDDING_FILTERS_ERROR,
  BIDDING_FILTERS_LOADING,
  BIDDING_FILTERS_SUCCESS,
} from "../../reducers/Bidding/BiddingFiltersReducer";
import { GetBiddingFiltersAPI } from "../../../services/bidding/biddingFiltersApi";

const loadingState = () => ({
  type: BIDDING_FILTERS_LOADING,
});

const successState = (payload: FilterGroup[]) => ({
  type: BIDDING_FILTERS_SUCCESS,
  payload,
});

const errorState = (error: string) => ({
  type: BIDDING_FILTERS_ERROR,
  error,
});

export const fetchBiddingFilters = (auth: AuthToken) => {
  return async (dispatch: Dispatch) => {
    dispatch(loadingState());
    try {
      const response = await GetBiddingFiltersAPI(auth);

      dispatch(successState(response));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(errorState(e));
      return Promise.reject(e);
    }
  };
};
