import React, { PropsWithChildren } from "react";
import { Container } from "react-bootstrap";
import { BodySafeMargin, Content } from "./MainTemplate.styles";

interface MainTemplate {
  title?: string;
  withoutContainer?: boolean;
}

function MainTemplate({
  children,
  title,
  withoutContainer,
}: PropsWithChildren<MainTemplate>) {
  const content = !withoutContainer ? (
    <Container fluid className="bg-white mt-4 p-4 rounded">
      {children}
    </Container>
  ) : (
    children
  );
  return (
    <Content>
      {/* {title && <HeadTitle title={title} />} */}
      <div className="w-100 position-relative">
        {/* <PageHeader title={title} /> */}
        <BodySafeMargin>{content}</BodySafeMargin>
      </div>
    </Content>
  );
}

export default MainTemplate;
