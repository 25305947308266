import React from "react";
import { Flex, Text } from "rebass";
import { Row, Col } from "react-bootstrap";
import RiskFindingCard from "../../components/riskFindingCard/RiskFindingCard";
import { ResultHeader } from "../companiesHighRisk/CompaniesHighRiskCardList";
import { BiddingListResponse } from "../../services/bidding/biddingListApi";

type BiddingListCardListProps = {
  biddingList: BiddingListResponse;
};

const BiddingCardList = ({ biddingList }: BiddingListCardListProps) => {
  if (!biddingList) {
    return null;
  }

  const { results } = biddingList;
  const { color = "green" } = results;

  const renderBiddingList = () => {
    if (!biddingList) {
      return null;
    }

    const { data } = biddingList;

    return data.map((d, i) => {
      const link = `/lote/${d.id_lote}`;

      const {
        superficial_alerts = 0,
        relevant_alerts = 0,
      } = d;

      return (
        <Col key={i} md={3} className={"mb-3"}>
          <RiskFindingCard
            title={d.title}
            subtitle={d.document}
            value={d.value}
            badge={d?.badge}
            progress={superficial_alerts}
            secondProgress={relevant_alerts}
            link={link}
            description={d.description}
          />
        </Col>
      );
    });
  };

  const renderResultContent = () => {
    const { content } = results;

    return content.map((c, i) => (
      <Flex key={i} flexDirection={"column"} alignItems={"center"}>
        <Text fontSize={"14px"} fontWeight={"bold"}>
          {c.title}
        </Text>
        <Text fontWeight={"bold"} fontSize={"35px"}>
          {c.value}
        </Text>
      </Flex>
    ));
  };

  return (
    <>
      <Row>
        <Col md={12} className={"mb-3"}>
          <ResultHeader backgrounColor={color}>
            {renderResultContent()}
          </ResultHeader>
          <Row>{renderBiddingList()}</Row>
        </Col>
      </Row>
    </>
  );
};

export default BiddingCardList;
