import { AuthToken } from "../../interfaces/AuthInterface";
import { API } from "../api";

export interface BiddingListBadge {
  label: string;
  value: number;
}

export interface BiddingListRow {
  title: string;
  document: string;
  id_lote: string;
  superficial_alerts: number;
  relevant_alerts: number;
  critical_alerts: number;
  value: number;
  badge: BiddingListBadge;
  description: string;
}

export interface BiddingListContent {
  title: string;
  value: number;
}

export interface BiddingListResults {
  color: string;
  icon: string;
  content: BiddingListContent[];
}

export interface BiddingListResponse {
  title: string;
  results: BiddingListResults;
  data: BiddingListRow[];
  BiddingList;
}

export const GetBiddingListAPI = async (
  auth: AuthToken,
  input: {
    [a: string]: any;
  }
): Promise<BiddingListResponse> => {
  try {
    return await API("filtrar_risco_lote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + auth.auth
      },
      body: JSON.stringify({ ...auth, input }),
    });
  } catch (error) {
    throw new Error(error);
  }
};
