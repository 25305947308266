import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import {
  BiddingInfo,
  BiddingResponse,
} from "../../../interfaces/models/Bidding";
import MaterialTable from "../../atoms/MaterialTable/MaterialTable";
import WarningHeader from "../../molecules/WarningHeader";

type RenderInfoProps = {
  prefix: string;
  info: BiddingInfo[];
};

const RenderInfo = ({ prefix, info }: RenderInfoProps) => {
  return (
    <div className="mt-4">
      {info?.map(({ label, value }) => {
        const id = `${prefix}-${label}`;

        return (
          <div key={label}>
            <p
              className="font-weight-bold mb-2"
              id={`${id}-label`}
              aria-describedby={`${id}-value`}
            >
              {label}
            </p>
            <p
              className="py-2 px-3 mb-4 border border-light-gray fake-input rounded"
              id={`${id}-value`}
              aria-labelledby={`${id}-label`}
            >
              {value}
            </p>
          </div>
        );
      })}
    </div>
  );
};

type BiddingTemplateProps = {
  bidding: BiddingResponse;
};

const BiddingTemplate = ({ bidding }: BiddingTemplateProps) => {
  const {
    tabela: { colunas, linhas, searchable },
    cabecalho,
    sumario_dir,
    sumario_esq
  } = bidding;

  return (
    <>
      <WarningHeader content={cabecalho} />
      <Container fluid className="mt-4 align-items-stretch p-0">
        <Row noGutters>
          <Col className="p-0 pr-lg-4" lg={6}>
            <div className="p-4 pb-2 bg-white h-100 rounded">
              <h2 className="h4 font-weight-bold">{sumario_esq.title}</h2>
              <RenderInfo prefix="lic" info={sumario_esq.data} />
            </div>
          </Col>
          <Col lg={6} className="bg-white p-4 pb-2 mt-3 m-lg-0 rounded">
            <h2 className="h4 font-weight-bold">{sumario_dir.title}</h2>
            <RenderInfo prefix="lot" info={sumario_dir.data} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-white mt-4 p-4 rounded">
        <h2 className="h4 font-weight-bold">Licitantes</h2>
        <MaterialTable
          columnWithOptions={colunas}
          data={linhas}
          searchLeft={searchable !== false}
          sticky
          noShadow
        />
      </Container>
    </>
  );
};

export default BiddingTemplate;
