import React, { createContext, PropsWithChildren, useState } from "react";

export const defaultSidebarSize = {
  small: 60,
  large: 200,
};

const defaultContext = {
  isClosed: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleClosed: () => {},
  defaultSidebarSize,
};

export const SidebarContext = createContext(defaultContext);

export function SidebarContextProvider({ children }: PropsWithChildren<{}>) {
  const [isClosed, setIsClosed] = useState(false);
  const toggleClosed = () => setIsClosed(!isClosed);

  return (
    <SidebarContext.Provider
      value={{ isClosed, toggleClosed, defaultSidebarSize }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
