import React, { useMemo } from "react";
import { Row } from "react-bootstrap";

import { AgencyDetailHeader } from "../../../interfaces/models/AgencyDetail";
import AgencyHeader from "../../atoms/AgencyHeader/AgencyHeader";

function HeaderEl({
	size,
	element: { cor, destaque, texto, icone },
}: {
	size: number;
	element: AgencyDetailHeader;
}) {
	return (
		<div className={`col-12 col-xl-${size} p-2`}>
			<AgencyHeader
				color={cor}
				text={destaque}
				description={texto}
				icon={icone}
			/>
		</div>
	);
}

function AgencyDetailHeaderEl({ header }: { header: AgencyDetailHeader[] }) {
	const render = useMemo(() => {
		switch (header.length) {
			case 2:
				return header.map((el) => (
					<HeaderEl key={el.destaque} element={el} size={6} />
				));
			case 3:
				return header.map((el) => (
					<HeaderEl key={el.destaque} element={el} size={4} />
				));
			case 4:
				return header.map((el) => (
					<HeaderEl key={el.destaque} element={el} size={6} />
				));
			case 5:
				return [
					<HeaderEl key={header[0].destaque} element={header[0]} size={4} />,
					<HeaderEl key={header[1].destaque} element={header[1]} size={4} />,
					<HeaderEl key={header[2].destaque} element={header[2]} size={4} />,
					<HeaderEl key={header[3].destaque} element={header[3]} size={6} />,
					<HeaderEl key={header[4].destaque} element={header[4]} size={6} />,
				];
			case 6:
				return [
					<HeaderEl key={header[0].destaque} element={header[0]} size={4} />,
					<HeaderEl key={header[1].destaque} element={header[1]} size={4} />,
					<HeaderEl key={header[2].destaque} element={header[2]} size={4} />,
					<HeaderEl key={header[3].destaque} element={header[3]} size={4} />,
					<HeaderEl key={header[4].destaque} element={header[4]} size={4} />,
					<HeaderEl key={header[5].destaque} element={header[5]} size={4} />,
				];
			default:
				return header.map((el) => (
					<HeaderEl key={el.destaque} element={el} size={12} />
				));
		}
	}, [header]);

	return <Row className="row-minus-px-2">{render}</Row>;
}

export default AgencyDetailHeaderEl;
