import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Button = styled.button`
	border: 0;
	background: transparent;
	padding: 1.25rem 1.5rem;
	position: absolute;
	top: 0;
	right: 0;
`;

function FullScreenButton({
	onClick,
	active,
	className,
}: {
	onClick: () => void;
	active: boolean;
	className?: string;
}) {
	return (
		<Button type="button" onClick={onClick} className={className}>
			<FontAwesomeIcon icon={active ? "times" : "expand-alt"} color="gray" />
		</Button>
	);
}

export default FullScreenButton;
