import React from "react";
import { Route, RouteProps } from "react-router-dom";

import AuthContainer from "../AuthContainer/AuthContainer";

function AuthRoute({ children, ...rest }: RouteProps) {
	return (
		<Route {...rest}>
			<AuthContainer>{children}</AuthContainer>
		</Route>
	);
}

export default AuthRoute;
