/* eslint-disable react/display-name */
import React, { Dispatch, useEffect, useReducer } from "react";
import { Container } from "react-bootstrap";

import MaterialTable from "../components/atoms/MaterialTable/MaterialTable";
import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { CommonTable } from "../interfaces/APIInterface";
import { AuthToken } from "../interfaces/AuthInterface";
import { AgencyResumeRow } from "../interfaces/models/AgencyResume";
import { PublicAgenciesAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<CommonTable<AgencyResumeRow>>();

const getPublicAgencies = async (
  auth: AuthToken,
  dispatch: Dispatch<SearchAction<CommonTable<AgencyResumeRow>>>
) => {
  dispatch({ type: "loading" });
  try {
    const result = await PublicAgenciesAPI(auth);
    dispatch({ type: "loaded", result });
  } catch (e) {
    dispatch({ type: "error", error: e });
  }
};

function PublicAgencies() {
  const [{ loading, error, result }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);

  useEffect(() => {
    getPublicAgencies(auth, dispatch);
  }, []);

  return (
    <MainTemplate title="Consultar Órgãos Públicos" withoutContainer>
      <LoadingError loading={loading} error={error} />
      {result && (
        <Container fluid className="bg-white p-4 mt-4">
          {result.Title && <h3 className="h5 font-weight-bold">{result.Title}</h3>}
          {result.descricao && <p className="mt-4">{result.descricao}</p>}
          <MaterialTable
            noShadow
            columnWithOptions={result?.colunas ?? []}
            data={result.linhas}
            searchLeft={result.searchable !== false}
            options={{
              paging: true,
              pageSize: 15,
              pageSizeOptions: [15, 25, 50, 100],
            }}
          />
        </Container>
      )}
    </MainTemplate>
  );
}

export default PublicAgencies;
