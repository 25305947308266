import { Http2ServerRequest } from "http2";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import WarningHeader from "../../../components/molecules/WarningHeader";
import intUrl from "../../../data/intUrl";
import { Empresa } from "../../../interfaces/models/PrintRiskThemeItemDetail";
import cnpjMask from "../../../utils/cnpjMask";
import formatMoney from "../../../utils/moneyFormat";

interface CompanyProps {
  company: Empresa;
}

function CompanyResume(props: CompanyProps) {
  const { company } = props;

  return (
    <Row className="p-3 rounded bg-light mb-3 print-avoid-break">
      <div className="w-100 d-flex justify-content-between">
        <div className="p-0">
          <h4 className="w-100">
            {company.nome} ({cnpjMask(company.documento)})
          </h4>
        </div>
        <div className="text-right p-0">
          <Link
            className="text-primary no-print pointer"
            style={{ textDecoration: "underline", fontWeight: "bolder" }}
            to={`/${intUrl.company}/${company.documento}`}
          >
            Ver mais
          </Link>
        </div>
      </div>
      <WarningHeader
        key={`header-company-${company.documento}`}
        content={company.cabecalho}
      />
      <Container className="d-flex p-0 my-3" style={{ gap: "1rem" }}>
        <Card className="w-100 p-3 rounded border-0 d-flex justify-content-between">
          <Card.Title className="font-larger">Total em contratos</Card.Title>
          <Card.Body className="p-0" style={{ flex: "none" }}>
            {formatMoney("monetario", true)(company?.valor_contratos_total)}
          </Card.Body>
        </Card>
        <Card className="w-100 p-3 rounded border-0 d-flex justify-content-between">
          <Card.Title className="font-larger">
            Quantidade de contratos
          </Card.Title>
          <Card.Body className="p-0" style={{ flex: "none" }}>
            {company?.n_contratos}
          </Card.Body>
        </Card>
        <Card className="w-100 p-3 rounded border-0 d-flex justify-content-between">
          <Card.Title className="font-larger">Quantidade de UASGs</Card.Title>
          <Card.Body className="p-0" style={{ flex: "none" }}>
            {company?.entidades}
          </Card.Body>
        </Card>
      </Container>
      <Container className="d-flex p-0 my-3 mt-0" style={{ gap: "1rem" }}>
        <Card className="w-100 p-3 rounded border-0 d-flex justify-content-between">
          <Card.Title className="font-larger">
            Total em contratos na Unidade Federativa
          </Card.Title>
          <Card.Body className="p-0" style={{ flex: "none" }}>
            {formatMoney("monetario", true)(company?.valor_contratos_estado)}
          </Card.Body>
        </Card>

        <Card className="w-100 p-3 rounded border-0 d-flex justify-content-between">
          <Card.Title className="font-larger">
            Quantidade de contratos na Unidade Federativa
          </Card.Title>
          <Card.Body className="p-0" style={{ flex: "none" }}>
            {company?.n_contratos_estado}
          </Card.Body>
        </Card>
        <Card className="w-100 p-3 rounded border-0 d-flex justify-content-between">
          <Card.Title className="font-larger">
            Quantidade de UASGs na Unidade Federativa
          </Card.Title>
          <Card.Body className="p-0" style={{ flex: "none" }}>
            {company?.entidades_estado}
          </Card.Body>
        </Card>
      </Container>
      <Container className="d-flex bg-white rounded py-1 px-2 only-print">
        <span>Relatório completo: </span>
        <Link
          className="text-primary"
          style={{ textDecoration: "underline", fontWeight: "bolder" }}
          to={`/${intUrl.company}/${company.documento}?print=true`}
        >
          {window.location.host}/{intUrl.company}/{company.documento}?print=true
        </Link>
      </Container>
    </Row>
  );
}

export default CompanyResume;
