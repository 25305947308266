import React, { Dispatch, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import LoadingError from "../../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../../components/reducers/SearchReducer";
import BiddingTemplate from "../../components/template/BiddingTemplate/BiddingTemplate";
import MainTemplate from "../../components/template/MainTemplate/MainTemplate";
import { AuthToken } from "../../interfaces/AuthInterface";
import { BiddingResponse } from "../../interfaces/models/Bidding";
import { BiddingAPI } from "../../services/api";
import useConnectedSelector from "../../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<BiddingResponse>();

const getBidding = (auth: AuthToken, id: string) => async (
  dispatch: Dispatch<SearchAction<BiddingResponse>>
) => {
  dispatch({ type: "loading" });
  try {
    const result = await BiddingAPI(auth, id);
    dispatch({ type: "loaded", result });
  } catch (e) {
    dispatch({ type: "error", error: e });
  }
};

function Bidding() {
  const [{ loading, result, error }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);
  const { id } = useParams<any>();

  useEffect(() => {
    getBidding(auth, id)(dispatch);
  }, []);

  return (
    <MainTemplate title={`Detalhe da Licitação n° ${id}`} withoutContainer>
      <LoadingError loading={loading} error={error} />
      {result && <BiddingTemplate bidding={result} />}
    </MainTemplate>
  );
}

export default Bidding;
