import React from "react";
import { Col, Row } from "react-bootstrap";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cls from "classnames";
import styled from "styled-components";

import { WarningHeaderInterface } from "../../interfaces/models/CommonTypes";

const IconContainer = styled.div.attrs({
  className: "d-flex justify-center align-items-center rounded-circle",
})`
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

const HeaderBackground = styled.div`
  background: white;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 6px;
    left: 0;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background: ${(props) => props.color};
  }
`;

const Title = styled.h2`
  color: ${(props) => props.color};
`;

const divideColumns = (total: number) => {
  switch (total) {
    case 1:
      return 12;
    case 3:
      return 4;
    default:
      return 6;
  }
};

interface WarningHeaderProps {
  content: WarningHeaderInterface[];
}

export default function WarningHeader({ content }: WarningHeaderProps) {
  if (!content) {
    return null;
  }

  const getAlertItems = (data) => {
    let items = data;
    if (!Array.isArray(data)) items = [data];
    return items?.map((row, index) => (
      <p key={row} className={index === items.length - 1 ? "mb-0" : "mb-1"}>
        {row}
      </p>
    ));
  };

  return (
    <Row className="align-items-strech">
      {content.map(({ data, icon, title, color = "#DF2F4D" }, index) => {
        const colClassName = cls(
          {
            "pt-3": index !== 0,
          },
          content.length > 3
            ? {
                "pt-lg-3": index > 1,
                "pt-lg-0": index < 2,
              }
            : {
                "pt-lg-0": true,
              }
        );

        return (
          <Col
            lg={divideColumns(content.length)}
            className={colClassName}
            key={title}
          >
            <HeaderBackground
              className="rounded p-4 h-100 d-flex flex-row align-items-start"
              color={color}
            >
              <IconContainer>
                <FontAwesomeIcon
                  icon={icon.replace("fas fa-", "") as IconName}
                  color={color}
                />
              </IconContainer>
              <div className="pt-1 px-3">
                <Title className="h5" color={color}>
                  {title}
                </Title>
                {getAlertItems(data)}
              </div>
            </HeaderBackground>
          </Col>
        );
      })}
    </Row>
  );
}
