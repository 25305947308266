import { LoginAPI, ChangeBaseAPI } from "../../services/api";
import { LoginAuth } from "../reducers/Login";
import { ChangePasswordAPI } from "../../services/api";
import {
	LOGIN_ERROR,
	LOGIN_LOADING,
	LOGIN_LOGOFF,
	LOGIN_SUCCESS,
} from "../reducers/Login/constants";
import { AuthToken } from "../../interfaces/AuthInterface";

const loadingState = () => ({
	type: LOGIN_LOADING,
});

const successState = (payload: LoginAuth) => ({
	type: LOGIN_SUCCESS,
	payload,
});

const errorState = (error: string) => ({
	type: LOGIN_ERROR,
	error,
});

export const logoff = (dispatch) => dispatch({ type: LOGIN_LOGOFF });

export const login = (user: string, pass: string) => {
	return async (dispatch) => {
		dispatch(loadingState());
		try {
			const response = await LoginAPI(user, pass);
			const auth = {
				auth: response.token,
				id: response.id_state,
			};
			dispatch(successState(auth));
			return Promise.resolve(auth);
		} catch (e) {
			dispatch(errorState(e));
			return Promise.reject(e);
		}
	};
};

export const change_user_id_base = (auth: AuthToken, id_base: number) => {
	return async (dispatch) => {
		dispatch(loadingState());
		try {
			const response = await ChangeBaseAPI(auth, id_base);
			dispatch({ type: LOGIN_LOGOFF});
			return Promise.resolve(auth);
		} catch (e) {
			dispatch(errorState(e));
			return Promise.reject(e);
		}
	};
};


export const change_password = (auth: AuthToken, pass: string) => {
	return async (dispatch) => {
		dispatch(loadingState());
		try {
			const response = await ChangePasswordAPI(auth, pass);
			dispatch({ type: LOGIN_LOGOFF});
			return Promise.resolve(auth);
		} catch (e) {
			dispatch(errorState(e));
			return Promise.reject(e);
		}
	};
};