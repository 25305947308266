import React from "react";
import { Container } from "react-bootstrap";

import { MapData } from "../../../interfaces/models/CommonTypes";
import Map from "../Map/Map";
import { renderDescription } from "../../organisms/ContentOrderer/ContentOrderer";

function RenderMap({ map: { descricao, lat, lng, titulo } }: { map: MapData }) {
  return (
    <Container
      fluid
      className="bg-white p-4 mt-4 rounded print-no-padding"
      key={titulo}
    >
      <h4 className="h5 font-weight-bold">{titulo}</h4>
      {renderDescription(descricao, lat && lng ? "mb-3" : "mb-0")}
      {lat && lng ? <Map lat={lat} lng={lng} /> : null}
    </Container>
  );
}

export default RenderMap;
