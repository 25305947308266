import React from "react";
import { Container, Spinner } from "react-bootstrap";

function LoadingContainer(props) {
  return (
    <Container
      className={`p-0 d-flex justify-content-center mt-4 ${props.className}`}
    >
      <div className="d-flex align-items-center mt-4 justify-content-center position-absolute">
        <div className="bg-white rounded p-4 d-flex align-items-center flex-column mt-auto">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Carregando...</span>
          </Spinner>
          <h2 className="h5 font-weight-bold mt-2 mb-3">Carregando</h2>
          <p className="mb-0">{props.message}</p>
        </div>
      </div>
    </Container>
  );
}

export default LoadingContainer;
