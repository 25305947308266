import styled from "styled-components";

export const BodySafeMargin = styled.div.attrs({
	className: "pb-4 px-5",
})`
	padding-top: calc(1.5rem + 72px);
`;

export const Content = styled.div`
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
`