// CompanyHighRiskFiltersReducer.ts
import { FilterGroup } from "../../../interfaces/models/FilterInterface";
import { LOGIN_LOGOFF } from "../Login/constants";

export const COMPANIES_HIGH_RISK_FILTERS_LOADING =
    "COMPANIES_HIGH_RISK_FILTERS_LOADING";
export const COMPANIES_HIGH_RISK_FILTERS_SUCCESS =
    "COMPANIES_HIGH_RISK_FILTERS_SUCCESS";
export const COMPANIES_HIGH_RISK_FILTERS_ERROR =
    "COMPANIES_HIGH_RISK_FILTERS_ERROR";

export interface CompaniesHighRiskFiltersState {
    loading: boolean;
    loaded: boolean;
    data?: FilterGroup[];
    error?: string;
}

export const initialState: CompaniesHighRiskFiltersState = {
    loading: false,
    loaded: false,
};


export type Action = {
  type: string;
  payload?: FilterGroup[];
  error?: string;
};

export default function CompaniesHighRiskFiltersReducer(
  state = initialState,
  action: Action
): CompaniesHighRiskFiltersState {
  switch (action.type) {
    case COMPANIES_HIGH_RISK_FILTERS_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case COMPANIES_HIGH_RISK_FILTERS_SUCCESS:
      return {
        ...initialState,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    case COMPANIES_HIGH_RISK_FILTERS_ERROR:
      return {
        ...initialState,
        loaded: true,
        error: action.error,
      };
    case LOGIN_LOGOFF:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
