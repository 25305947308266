import { GraphContent } from "../interfaces/models/CommonTypes";

const currencyFormatter = new Intl.NumberFormat("pt-BR", {
	style: "currency",
	currency: "BRL",
});

const moneySmallFormatter = (value: number) => {
	if (value >= 0 && value < 1000) {
		return `R$ ${value}`;
	}
	if (value >= 1000 && value < 1000000) {
		return `R$ ${Math.round(value / 1000)} mil`;
	}
	if (value >= 1000000 && value < 1000000000) {
		return `R$ ${Math.round(value / 1000000)} M`;
	}
	return `R$ ${Math.round(value / 1000000000)} B`;
};

const formatMoney = (content: GraphContent, tooltip?: boolean) => (
	value: number
) => {
	switch (content) {
		case "monetario":
			return !tooltip
				? moneySmallFormatter(value)
				: currencyFormatter.format(value);
		case "percentual":
			return `${value * 100} %`;
		default:
			return value;
	}
};

export default formatMoney;
