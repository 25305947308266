import React, { useEffect, useState } from "react";
import { Container, Form, Card } from "react-bootstrap";
import LoadingContainer from "../../components/template/LoadingContainer/LoadingContainer";
import MainTemplate from "../../components/template/MainTemplate/MainTemplate";
import { listUfBrasil } from "../../data/ufList";
import { PrintRiskThemeItem } from "../../interfaces/models/PrintRiskThemeItem";
import { PrintThemeDashData } from "../../interfaces/models/PrintThemeDashData";
import {
  PrintRiskThemeDashAPI,
  PrintRiskThemeListAPI,
} from "../../services/api";
import useConnectedSelector from "../../utils/useConnectedSelector";
import Dashboard from "./components/Dashboard";
import Theme from "./components/Theme";

function PrintRiskTheme() {
  const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);

  const [uf, setUF] = useState(null);
  const [loading, setLoading] = useState(false);
  const [themes, setThemes] = useState<any>();
  const [dashData, setDashData] = useState<PrintThemeDashData>();

  useEffect(() => {
    async function getDashData() {
      if (!uf) return;
      const res = await PrintRiskThemeDashAPI(auth, uf);
      setDashData(res);
    }

    async function getThemes() {
      if (!uf) return;
      setLoading(true);
      const res = await PrintRiskThemeListAPI(auth, uf);
      const arrThemes = [];
      res.forEach((theme) => {
        if (!arrThemes[theme.macro_tema]) arrThemes[theme.macro_tema] = [];
        arrThemes[theme.macro_tema].push(theme);
      });
      setLoading(false);
      setThemes(arrThemes);
    }

    getDashData();
    getThemes();
  }, [uf]);

  const getThemeContainers = (themes) => {
    if (!themes) return;
    return Object.keys(themes).map((macroTheme) => (
      <Container fluid className="mt-4 bg-white p-4 rounded">
        <h5 className="mb-4">{macroTheme}</h5>
        <Card className="border-0">
          <div className="row w-100 px-3 font-weight-bold">
            <span className="col-6 p-0">Tema de risco</span>
            <span className="col-2 p-0">Total em contratos</span>
            <span className="col-2 p-0">Contratos da UF</span>
            <span className="col p-0">UASGs</span>
            <span className="col p-0" />
          </div>
          {themes[macroTheme].map((theme) => {
            return (
              <Theme
                key={theme.tema}
                themeData={theme}
                generalData={dashData}
                uf={uf}
              />
            );
          })}
        </Card>
      </Container>
    ));
  };

  return (
    <MainTemplate title="Temas de risco" withoutContainer>
      <Container
        fluid
        className="bg-white p-4 rounded d-flex flex-column border-top"
      >
        <h4>Empresas de risco</h4>
        <Container className=" d-flex flex-column bg-transparent py-4 px-0 border-top border-bottom">
          <span className="font-weight-bold">Unidade Federativa da UASG</span>
          <Form.Control
            className="mt-1 w-50"
            as="select"
            custom
            onChange={(e) => setUF(e.target.value)}
          >
            <option value="">Selecione</option>
            {listUfBrasil.map((uf) => (
              <option
                key={`uf-item-${uf.label}`}
                value={uf.value.toUpperCase()}
              >
                {uf.description}
              </option>
            ))}
          </Form.Control>
        </Container>
      </Container>
      {!!uf && loading && <LoadingContainer message={"Buscando empresas"} />}
      {!loading && !!uf && (
        <>
          <div className="badge badge-primary rounded mt-4 p-4">
            Dados referentes aos últimos 5 anos
          </div>
          <Dashboard data={dashData} className="p-4" />
          {getThemeContainers(themes)}
        </>
      )}
    </MainTemplate>
  );
}

export default PrintRiskTheme;
