import React from "react";
import { Container } from "react-bootstrap";
import { PrintThemeDashData } from "../../../interfaces/models/PrintThemeDashData";
import formatMoney from "../../../utils/moneyFormat";

function Dashboard(props) {
  const dashData: PrintThemeDashData = props.data;

  return (
    <Container
      className={`bg-white rounded m-0 mt-4 d-flex flex-column ${props.className}`}
      style={{ gap: "1.5rem" }}
    >
      <h5 className="mb-0 no-print">Empresas de maior risco</h5>
      <Container className="d-flex flex-row m-0 p-0" style={{ gap: "1rem" }}>
        <Container className="bg-light border rounded w-100 text-center d-flex flex-column py-4 justify-content-between">
          <span className="mb-2 font-weight-bold">Total em contratos</span>
          <h3
            className="text-primary font-weight-bold"
            title={formatMoney(
              "monetario",
              true
            )(dashData?.valor_contratos_total ?? 0).toString()}
          >
            {formatMoney("monetario")(dashData?.valor_contratos_total ?? 0)}
          </h3>
        </Container>
        <Container className="bg-light border rounded w-100 text-center d-flex flex-column py-4 justify-content-between">
          <span className="mb-2 font-weight-bold">Quantidade de contratos</span>
          <h3 className="text-primary font-weight-bold">
            {dashData?.n_contratos ?? 0}
          </h3>
        </Container>
        <Container className="bg-light border rounded w-100 text-center d-flex flex-column py-4 justify-content-between">
          <span
            className="mb-2 font-weight-bold"
            title="Unidades de Administração de Serviços Gerais"
          >
            Quantidade de UASGs
          </span>
          <h3 className="text-primary font-weight-bold">
            {dashData?.entidades ?? 0}
          </h3>
        </Container>
      </Container>
      <Container
        className="d-flex flex-row m-0 p-0 print-mt-3"
        style={{ gap: "1rem" }}
      >
        <Container className="bg-light border rounded w-100 text-center d-flex flex-column py-4 justify-content-between">
          <span className="mb-2 font-weight-bold">
            Total em contratos na Unidade Federativa
          </span>
          <h3
            className="text-primary font-weight-bold"
            title={formatMoney(
              "monetario",
              true
            )(dashData?.valor_contratos_estado ?? 0).toString()}
          >
            {formatMoney("monetario")(dashData?.valor_contratos_estado ?? 0)}
          </h3>
        </Container>
        <Container className="bg-light border rounded w-100 text-center d-flex flex-column py-4 justify-content-between">
          <span className="mb-2 font-weight-bold">
            Quantidade de contratos na Unidade Federativa
          </span>
          <h3 className="text-primary font-weight-bold">
            {dashData?.n_contratos_estado ?? 0}
          </h3>
        </Container>
        <Container className="bg-light border rounded w-100 text-center d-flex flex-column py-4 justify-content-between">
          <span
            className="mb-2 font-weight-bold"
            title="Unidades de Administração de Serviços Gerais"
          >
            Quantidade de UASGs na Unidade Federativa
          </span>
          <h3 className="text-primary font-weight-bold">
            {dashData?.entidades_estado ?? 0}
          </h3>
        </Container>
      </Container>
    </Container>
  );
}

export default Dashboard;
