import { FilterGroup } from "../../../interfaces/models/FilterInterface";
import { LOGIN_LOGOFF } from "../Login/constants";
import { FILTERS_ERROR, FILTERS_LOADING, FILTERS_SUCCESS } from "./constants";

export interface FiltersState {
  loading: boolean;
  loaded: boolean;
  data?: FilterGroup[];
  error?: string;
}

export const initialState: FiltersState = {
  loading: false,
  loaded: false,
};

export type Action = {
  type: string;
  payload?: FilterGroup[];
  error?: string;
};

export default function FiltersReducer(
  state = initialState,
  action: Action
): FiltersState {
  switch (action.type) {
    case FILTERS_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case FILTERS_SUCCESS:
      return {
        ...initialState,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    case FILTERS_ERROR:
      return {
        ...initialState,
        loaded: true,
        error: action.error,
      };
    case LOGIN_LOGOFF:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
