import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { Redirect } from "react-router-dom";

import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { logoff } from "../store/actions/LoginActions";
import useConnectedSelector from "../utils/useConnectedSelector";

function Logoff() {
	const { dispatch } = useStore();
	const auth = useConnectedSelector(({ LoginReducer }) => LoginReducer.data);
	useEffect(() => {
		logoff(dispatch);
	}, []);
	return auth ? (
		<MainTemplate title="Deslogando">
			<h3>Aguarde</h3>
		</MainTemplate>
	) : (
		<Redirect to="/" />
	);
}

export default Logoff;
