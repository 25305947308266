import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import localStor from "redux-persist/lib/storage";
// import sessionStor from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";

import reducer from "./reducers";
const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: "root",
	storage: localStor,
	// storage: sessionStor,
	stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
