import { UFItem } from "../interfaces/models/UFItem";

export const listUfBrasil: UFItem[] = [
  { label: "AC", value: "AC", description: "Acre" },
  { label: "AL", value: "AL", description: "Alagoas" },
  { label: "AM", value: "AM", description: "Amazonas" },
  { label: "AP", value: "AP", description: "Amapá" },
  { label: "BA", value: "BA", description: "Bahia" },
  { label: "CE", value: "CE", description: "Ceará" },
  { label: "DF", value: "DF", description: "Distrito Federal" },
  { label: "ES", value: "ES", description: "Espírito Santo" },
  { label: "GO", value: "GO", description: "Goiás" },
  { label: "MA", value: "MA", description: "Maranhão" },
  { label: "MG", value: "MG", description: "Minas Gerais" },
  { label: "MS", value: "MS", description: "Mato Grosso do Sul" },
  { label: "MT", value: "MT", description: "Mato Grosso" },
  { label: "PA", value: "PA", description: "Pará" },
  { label: "PB", value: "PB", description: "Paraíba" },
  { label: "PE", value: "PE", description: "Pernambuco" },
  { label: "PI", value: "PI", description: "Piauí" },
  { label: "RN", value: "RN", description: "Rio Grande do Norte" },
  { label: "RO", value: "RO", description: "Rondônia" },
  { label: "RR", value: "RR", description: "Roraima" },
  { label: "PR", value: "PR", description: "Paraná" },
  { label: "RJ", value: "RJ", description: "Rio de Janeiro" },
  { label: "RS", value: "RS", description: "Rio Grande do Sul" },
  { label: "SE", value: "SE", description: "Sergipe" },
  { label: "SC", value: "SC", description: "Santa Catarina" },
  { label: "SP", value: "SP", description: "São Paulo" },
  { label: "TO", value: "TO", description: "Tocantins" },
];
