import React, { ReactNode, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useStore } from "react-redux";
import { fetchSuperiorAgencies } from "../../../store/actions/SuperiorAgencyActions";
import useConnectedSelector from "../../../utils/useConnectedSelector";

function LoadingSuperiorAgenciesContainer({ children }: { children?: ReactNode }) {
  const { dispatch } = useStore();
  const {
    auth,
    superiorAgenciesLoaded,
  } = useConnectedSelector(
    ({
      SuperiorAgenciesReducer,
      LoginReducer,
    }) => ({
      auth: LoginReducer.data,
      superiorAgenciesLoaded: SuperiorAgenciesReducer.loaded,
    })
  );

  useEffect(() => {
    const load = async()=>{
      await fetchSuperiorAgencies(auth)(dispatch);
    }
    load();
  }, []);

  if (superiorAgenciesLoaded) return <>{children}</>;

  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <div className="bg-white rounded p-4 d-flex align-items-center flex-column">
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Carregando...</span>
        </Spinner>
        <h2 className="h5 font-weight-bold mt-2 mb-3">Carregando</h2>
          <p className="mb-2">Carregando Órgãos Superiores </p>
      </div>
    </div>
  );
}

export default LoadingSuperiorAgenciesContainer;
