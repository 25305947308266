import React, { useMemo } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import cls from "classnames";

import { CommonTableContainer } from "../../../interfaces/models/CommonTypes";
import FullScreenButton from "../../atoms/FullScreenButton/FullScreenButton";
import MaterialTable from "../../atoms/MaterialTable/MaterialTable";
import { useFullScreen } from "./Bock.utils";
import { renderDescription } from "../../organisms/ContentOrderer/ContentOrderer";

function TableBlock(props: { table: CommonTableContainer<any> }) {
  const {
    id,
    titulo,
    ordem,
    condicoes,
    descricao,
    alert,
    dados: { colunas, linhas, searchable, detail },
  } = props.table;

  const [isFullScreen, toggleFullScreen] = useFullScreen();
  const className = cls("p-4 bg-white print-no-padding", {
    "position-fixed fixed-full top-70 fixed-front overflow-auto": isFullScreen,
    "position-relative mt-4": !isFullScreen,
  });

  const table = useMemo(
    () =>
      !isFullScreen ? (
        <MaterialTable
          className="mt-4"
          hasSearch={searchable}
          searchLeft
          sticky
          noShadow
          columnWithOptions={colunas}
          data={linhas}
          detail={detail}
        />
      ) : (
        <>
          <MaterialTable
            className="mt-4"
            hasSearch={searchable}
            searchLeft
            noShadow
            columnWithOptions={colunas}
            data={linhas}
            detail={detail}
          />
        </>
      ),
    [isFullScreen, linhas, colunas, searchable, detail]
  );

  return (
    <Container id={id} fluid className={className} key={ordem}>
      <h3 className="h5 font-weight-bold">{titulo}</h3>
      <FullScreenButton
        active={isFullScreen}
        onClick={toggleFullScreen}
        className="no-print"
      />
      {(descricao && !condicoes) || (!descricao && condicoes)
        ? // @ts-ignore
          renderDescription(descricao || condicoes, "mt-4")
        : null}
      {descricao && condicoes ? (
        <Row className="mt-4">
          <Col md={12} as="p" className="word-wrap-anywhere">
            {descricao}
          </Col>
          <Col md={12} as="p" className="word-wrap-anywhere">
            {condicoes}
          </Col>
        </Row>
      ) : null}
      {alert && alert.length
        ? alert.map(({ type, content }) => (
            <Alert key={content} variant={type} className="mt-4">
              {content}
            </Alert>
          ))
        : null}
      {linhas && linhas.length ? table : null}
    </Container>
  );
}

export default TableBlock;
