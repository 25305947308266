interface Payload<Result> {
  result?: Result;
  error?: string;
}

interface PageState<Result> extends Payload<Result> {
  loading: boolean;
}

export interface SearchAction<Result> extends Payload<Result> {
  type: "loading" | "loaded" | "error";
}

type Modifier<Result> = (action: SearchAction<Result>) => Payload<Result>;

const defaultModifier: Modifier<any> = (action) => ({
  result: action.result,
});

export default function SearchReducer<Result>(
  initialState: PageState<Result> = { loading: false },
  loadedModifier: Modifier<Result> = defaultModifier
): [
  PageState<Result>,
  (state: PageState<Result>, action: SearchAction<Result>) => PageState<Result>
] {
  return [
    initialState,
    function StateReduce(
      state = initialState,
      action: SearchAction<Result>
    ): PageState<Result> {
      switch (action.type) {
        case "loading":
          return { ...initialState, loading: true };
        case "loaded":
          return {
            ...initialState,
            loading: false,
            ...loadedModifier(action),
          };
        case "error":
          return { ...initialState, error: action.error };
        default:
          return state;
      }
    },
  ];
}
