import { combineReducers } from "redux";

import FaqReducer from "./Faq";
import FiltersReducer from "./Filters";
import LoginReducer from "./Login";
import SuperiorAgenciesReducer from "./SuperiorAgencies";

import CompaniesHighRiskReducer from "./CompaniesHighRisk/CompaniesHighRiskReducer";
import CompaniesHighRiskFiltersReducer from "./CompaniesHighRisk/CompaniesHighRiskFiltersReducer";

import BiddingFiltersReducer from './Bidding/BiddingFiltersReducer';
import BiddingFiltersListReducer from './Bidding/BiddingFiltersListReducer';
import BiddingListReducer from './Bidding/BiddingListReducer';

const reducers = combineReducers({
	LoginReducer,
	SuperiorAgenciesReducer,
	FiltersReducer,
	FaqReducer,
	CompaniesHighRiskReducer,
	CompaniesHighRiskFiltersReducer,
	BiddingFiltersReducer,
	BiddingFiltersListReducer,
	BiddingListReducer,
});

export type RootReducers = ReturnType<typeof reducers>;

export default reducers;
