import React, { Dispatch, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

import LoadingError from "../components/molecules/LoadingError/LoadingError";
import SearchReducer, {
  SearchAction,
} from "../components/reducers/SearchReducer";
import BeneficiaryDetailTemplate from "../components/template/BeneficiaryDetailTemplate/BeneficiaryDetailTemplate";
import MainTemplate from "../components/template/MainTemplate/MainTemplate";
import { AuthToken } from "../interfaces/AuthInterface";
import { BeneficiaryDetail as BeneficiaryDetailResponse } from "../interfaces/models/BeneficiaryDetail";
import { BeneficiaryDetailAPI } from "../services/api";
import useConnectedSelector from "../utils/useConnectedSelector";

const [initialState, reducer] = SearchReducer<BeneficiaryDetailResponse>();

const getBeneficiary = (auth: AuthToken, cnpj: string) => async (
  dispatch: Dispatch<SearchAction<BeneficiaryDetailResponse>>
) => {
  dispatch({ type: "loading" });
  try {
    const company = await BeneficiaryDetailAPI(auth, cnpj);
    dispatch({ type: "loaded", result: company });
  } catch (error) {
    dispatch({ type: "error", error });
  }
};

function BeneficiaryDetail() {
  const [{ loading, result, error }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const auth = useConnectedSelector(({ LoginReducer: { data } }) => data);
  const { id } = useParams<any>();

  useEffect(() => {
    getBeneficiary(auth, id)(dispatch);
  }, []);

  return (
    <MainTemplate title="Detalhamento de beneficiario" withoutContainer>
      <LoadingError loading={loading} error={error} />
      {result && <BeneficiaryDetailTemplate company={result} />}
    </MainTemplate>
  );
}

export default BeneficiaryDetail;
