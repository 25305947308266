import { Dispatch } from "redux";

import { CommonTable } from "../../interfaces/APIInterface";
import { AuthToken } from "../../interfaces/AuthInterface";
import { AgencyResumeRow } from "../../interfaces/models/AgencyResume";
import { SuperiorAgenciesAPI } from "../../services/api";
import {
	PUBLIC_AGENCIES_ERROR,
	PUBLIC_AGENCIES_LOADING,
	PUBLIC_AGENCIES_SUCCESS,
} from "../reducers/SuperiorAgencies/constants";

const loadingState = () => ({
	type: PUBLIC_AGENCIES_LOADING,
});

const successState = (payload: CommonTable<AgencyResumeRow>) => ({
	type: PUBLIC_AGENCIES_SUCCESS,
	payload,
});

const errorState = (error: string) => ({
	type: PUBLIC_AGENCIES_ERROR,
	error,
});

export const fetchSuperiorAgencies = (auth: AuthToken) => {
	return async (dispatch: Dispatch) => {
		dispatch(loadingState());
		try {
			const response = await SuperiorAgenciesAPI(auth);

			dispatch(successState(response));
			return Promise.resolve(response);
		} catch (e) {
			dispatch(errorState(e));
			return Promise.reject(e);
		}
	};
};
