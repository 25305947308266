import React, { useContext, useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { menuDropdown } from "../../../data/menu";
import {
  SidebarContext,
  defaultSidebarSize,
} from "../../contexts/SidebarContext";
import logo from "../../../assets/images/logo.png";
import HeaderItem from "./HeaderItem";
import styled, { css } from "styled-components";
import { SidebarLogoContainer } from "./Header.styles";

export interface SidebarTheme {
  theme: { isClosed: boolean; defaultSidebarSize: typeof defaultSidebarSize };
}

export const SiderbarMenu = styled.nav.attrs({
  className: "position-fixed no-print",
})(
  ({ theme }: SidebarTheme) => css`
    background: #263544;
    width: 100%;
    display: flex;
    z-index: 9999;
    height: 70px;

    img {
      height: 50px;
    }
  `
);

export const SidebarUl = styled.ul`
  list-style: none;
  padding: 0;
  overflow: hidden;
  width: 100%;
  display: flex;
  margin-bottom: 0;
`;

const Sidebar = () => {
  const { isClosed, defaultSidebarSize } = useContext(SidebarContext);

  const items = useMemo(
    () => menuDropdown.map((menu) => <HeaderItem key={menu.id} {...menu} />),
    []
  );

  return (
    <ThemeProvider theme={{ isClosed, defaultSidebarSize }}>
      <SiderbarMenu>
        <SidebarLogoContainer>
          <img src={logo} alt="29 Analytics Logo" />
        </SidebarLogoContainer>
        <SidebarUl>{items}</SidebarUl>
      </SiderbarMenu>
    </ThemeProvider>
  );
};

export default Sidebar;
