import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import AuthRoute from "./app/components/atoms/AuthRoute/AuthRoute";
import HeadTitle from "./app/components/atoms/HeadTitle/HeadTitle";
import { SidebarContextProvider } from "./app/components/contexts/SidebarContext";
import AuthTemplate from "./app/components/template/AuthTemplate/AuthTemplate";
import LoadingFaqContainer from "./app/components/template/LoadingFaqContainer/LoadingFaqContainer";
import LoadingFiltersContainer from "./app/components/template/LoadingFiltersContainer/LoadingFiltersContainer";
import LoadingSuperiorAgenciesContainer from "./app/components/template/LoadingSuperiorAgenciesContainer/LoadingSuperiorAgenciesContainer";
import LoadingCompaniesHighRiskContainer from "./app/components/template/LoadingCompaniesHighRiskContainer/LoadingCompaniesHighRiskContainer";
import intUrl from "./app/data/intUrl";
import ChangeBase from "./app/pages/ChangeBase";
import Beneficiaries from "./app/pages/Beneficiaries";
import BeneficiaryDetail from "./app/pages/BeneficiaryDetail";
import Bidding from "./app/pages/bidding/Bidding";
import Companies from "./app/pages/Companies";
import CompanyDetail from "./app/pages/CompanyDetail";
import Faq from "./app/pages/Faq";
import Filter from "./app/pages/Filter";
import Home from "./app/pages/Home";
import Login from "./app/pages/Login";
import Logoff from "./app/pages/Logoff";
import PoliticianDetail from "./app/pages/PoliticianDetail";
import Politicians from "./app/pages/Politicians";
import PublicAgencies from "./app/pages/PublicAgencies";
import PublicAgencyDetail from "./app/pages/PublicAgencyDetail";
import SuperiorAgencies from "./app/pages/SuperiorAgencies";
import SuperiorAgencyDetail from "./app/pages/SuperiorAgencyDetail";
import ChangePassword from "./app/pages/ChangePassword";
import { persistor, store } from "./app/store/store";
import iconsLibrary from "./app/utils/iconsLibrary";
import CompaniesHighRisk from "./app/pages/companiesHighRisk/CompaniesHighRisk";
import ExternalLinks from "./app/pages/ExternalLinks";
import BiddingFilter from "./app/pages/bidding/biddingFilter/BiddingFilter";
import BiddingWrapper from "./app/pages/bidding/BiddingWrapper";
import RiskTheme from "./app/pages/riskTheme/RiskTheme";
import PrintRiskTheme from "./app/pages/printRiskTheme/PrintRiskTheme";

iconsLibrary();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <PersistGate persistor={persistor} loading={null}>
          <>
            <HeadTitle />
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <AuthRoute path="/">
                <SidebarContextProvider>
                  <AuthTemplate>
                    <Route path="/home">
                      <Home />
                    </Route>
                    <Route path={`/${intUrl.filter}`}>
                      <LoadingFiltersContainer>
                        <Filter />
                      </LoadingFiltersContainer>
                    </Route>
                    <Route path={`/${intUrl.biddingFilter}`} exact={true}>
                      <LoadingFiltersContainer>
                        <BiddingFilter />
                      </LoadingFiltersContainer>
                    </Route>
                    <Route path={`/${intUrl.companies}`}>
                      <Companies />
                    </Route>
                    <Route path={`/${intUrl.company}/:id`}>
                      <CompanyDetail />
                    </Route>
                    <Route path={`/${intUrl.publicAgencies}`}>
                      <PublicAgencies />
                    </Route>
                    <Route path={`/${intUrl.publicAgency}/:id`}>
                      <PublicAgencyDetail />
                    </Route>
                    <Route path={`/${intUrl.superiorAgencies}`}>
                      <LoadingSuperiorAgenciesContainer>
                        <SuperiorAgencies />
                      </LoadingSuperiorAgenciesContainer>
                    </Route>
                    <Route path={`/${intUrl.superiorAgency}/:id`}>
                      <SuperiorAgencyDetail />
                    </Route>
                    <Route path={`/${intUrl.bidding}/:id`}>
                      <Bidding />
                    </Route>
                    <Route path={`/${intUrl.politicians}`}>
                      <Politicians />
                    </Route>
                    <Route path={`/${intUrl.politician}/:id`}>
                      <PoliticianDetail />
                    </Route>
                    <Route path={`/${intUrl.beneficiaries}`}>
                      <Beneficiaries />
                    </Route>
                    <Route path={`/${intUrl.beneficiary}/:id`}>
                      <BeneficiaryDetail />
                    </Route>
                    <Route path={`/${intUrl.biddingList}`} exact={true}>
                      <BiddingWrapper />
                    </Route>
                    <Route path={`/${intUrl.riskTheme}`} exact={true}>
                      <PrintRiskTheme />
                    </Route>
                    <Route path={`/${intUrl.externalLinks}`}>
                      <ExternalLinks />
                    </Route>
                    <Route path={`/${intUrl.change_base}`} exact={true}>
                      <ChangeBase />
                    </Route>
                    <Route path={`/${intUrl.change_password}`} exact={true}>
                      <ChangePassword />
                    </Route>
                    <Route path="/logoff">
                      <Logoff />
                    </Route>
                  </AuthTemplate>
                </SidebarContextProvider>
              </AuthRoute>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </>
        </PersistGate>
      </Router>
    </Provider>
  );
}

export default App;
