import React from "react";
import { Link } from "react-router-dom";
import currency from "currency.js";
import { Column } from "material-table";
import {date, percent} from '../utils/formatter'

import Checkbox from "../components/atoms/Checkbox/Checkbox";
import { CommonTableColumn } from "../interfaces/APIInterface";

function renderLink(column: CommonTableColumn) {
  // eslint-disable-next-line react/display-name
  return (row: any) => {
    const { url, field, fieldValidator } = column.render.options;

    if (fieldValidator && !row[fieldValidator]) {
      return row[column.field];
    }

    return (
      <p style={{ textAlign: column.render?.align || "left", marginBottom: 0 }}>
        <Link to={`/${url}/${row[field]}`}>{row[column.field]}</Link>
      </p>
    );
  };
}

const renderExternalLink = (column: CommonTableColumn) => {
  // eslint-disable-next-line react/display-name
  return (row: any) => {
    const { field, fieldValidator } = column.render.options;

    if (fieldValidator && !row[fieldValidator]) {
      return row[column.field];
    }

    return (
        <p style={{ textAlign: column.render?.align || "left", marginBottom: 0 }}>
          <a href={row[field]} target={'_blank'}>{row[column.field]}</a>
        </p>
    );
  };
}

const moneyOptions = {
  separator: ".",
  decimal: ",",
  symbol: "R$ ",
};

function renderMoney(column: CommonTableColumn) {
  // eslint-disable-next-line react/display-name
  return (row: any) => {
    return (
      <p style={{ textAlign: column.render?.align || "left", marginBottom: 0 }}>
        {currency(row[column.field], moneyOptions).format()}
      </p>
    );
  };
}

function renderBool(column: CommonTableColumn) {
  // eslint-disable-next-line react/display-name
  return (row: any) => {
    const value = row[column.field];
    if (value === null) return "";
    return (
      <p style={{ textAlign: column.render?.align || "left", marginBottom: 0 }}>
        {<Checkbox value={value === true} />}
      </p>
    );
  };
}

function renderDate(column: CommonTableColumn) {
   // eslint-disable-next-line react/display-name
   return (row: any) => {
    return (
      <p style={{ textAlign: column.render?.align || "left", marginBottom: 0 }}>
        {date(row[column.field])}
      </p>
    );
  };
}

function renderPercent(column: CommonTableColumn) {
 // eslint-disable-next-line react/display-name
 return (row: any) => {
  return (
    <p style={{ textAlign: column.render?.align || "left", marginBottom: 0 }}>
      {percent(row[column.field])}
    </p>
  );
};
}


function render<T>(column: CommonTableColumn) {
  if (!column.render) return undefined;

  switch (column.render.type) {
    case "money":
      return renderMoney(column);
    case "link":
      return renderLink(column);
    case "bool":
      return renderBool(column);
    case "external-link":
      return renderExternalLink(column);
    case "date":
      return renderDate(column);
    case "percent":
      return renderPercent(column);
    default:
      return undefined;
  }
}

export default function renderColumn<T>(
  columns?: CommonTableColumn[]
): Column<object>[] {
  return columns && columns.length
    ? columns.map((column) => ({
        ...column,
        render: render<T>(column),
      }))
    : [];
}
