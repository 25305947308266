import React, { useEffect } from "react";
import useConnectedSelector from "../../utils/useConnectedSelector";
// import { send } from "../../store/actions/bidding/BiddingFiltersListActions";
import MainTemplate from "../../components/template/MainTemplate/MainTemplate";
import FilterFormFormik from "../filterFormFormik/FilterFormFormik";
import { useStore } from "react-redux";
import { Container } from "react-bootstrap";
import { sendBiddingList } from "../../store/actions/bidding/BiddingListActions";
import LoadingError from "../../components/molecules/LoadingError/LoadingError";
import BiddingCardList from "./BiddingCardList";

const BiddingWrapper = () => {
  const { dispatch } = useStore();

  const {
    auth,
    biddingFiltersList,
    biddingFiltersListLoading,
    biddingListLoaded,
    biddingFiltersListError,
    biddingList,
    biddingListLoading,
    biddingListError,
  } = useConnectedSelector(
    ({ BiddingListReducer, BiddingFiltersListReducer, LoginReducer }) => {
      return {
        auth: LoginReducer.data,
        biddingFiltersList: BiddingFiltersListReducer.data,
        biddingFiltersListLoading: BiddingFiltersListReducer.loading,
        biddingFiltersListError: BiddingFiltersListReducer.error,
        biddingList: BiddingListReducer.data,
        biddingListLoading: BiddingListReducer.loading,
        biddingListLoaded: BiddingListReducer.loaded,
        biddingListError: BiddingListReducer.error,
      };
    }
  );

  useEffect(() => {
    const initialFilters = { input: { filtros_gerais: { uf: "BR" } } };
    sendBiddingList(auth, dispatch)(initialFilters);
  }, []);

  return (
    <MainTemplate title="Empresas de alto risco" withoutContainer>
      <Container fluid className="bg-white mt-4 pt-0 px-4 pb-4 rounded">
        <FilterFormFormik
          sendFilter={sendBiddingList}
          filters={biddingFiltersList}
          loading={biddingListLoading}
          filterLabel={"Filtrar Lotes"}
          renderAccordion={false}
        />
      </Container>
      <LoadingError
        loading={false}
        error={biddingListError || biddingFiltersListError}
      />
      <Container fluid className="bg-transparent mt-4 pt-0 px-0 pb-4">
        {biddingListLoaded && <BiddingCardList biddingList={biddingList} />}
      </Container>
    </MainTemplate>
  );
};

export default BiddingWrapper;
