import React from "react";

import styled from "styled-components";

import { colors } from "../../styles/colors";

import { Badge, ProgressBar } from "../../ui";

import { Flex, Text } from "rebass";
import { Link } from "react-router-dom";

const { white, greyFont, greyShadow, red, yellow } = colors;

export const Container = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 7px ${greyShadow};
  border-radius: 5px;
  padding: 14px;
  flex: 1;
  height: 100%;
`;

export const Value = styled.span`
  font-weight: 700;
  font-size: 25px;
  color: ${red};
`;

export const BadgeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Level = styled.span`
  color: ${white};
  font-size: 12px;
`;

export const Warning = styled.span`
  font-weight: 700;
  font-size: 18px;
`;

type ProgressBadgeProps = {
  value: number;
  label: string;
};

type RiskFindingCardProps = {
  title: string | null;
  subtitle: string | null;
  value: string | number | null;
  progress: number | null;
  secondProgress: number | null;
  badge: ProgressBadgeProps | null;
  link: string | null;
  description?: string | null;
};

const RiskFindingCard = ({
  title = "title",
  subtitle = "subtitle",
  value,
  progress,
  secondProgress,
  badge,
  link,
                           description,
}: RiskFindingCardProps) => {
  const renderBadge = () => {
    if (!badge) {
      return null;
    }

    return (
      <Flex ml={"15px"}>
        <Badge
          color={red}
          size={50}
          content={
            <BadgeContent>
              <Warning>{badge?.value}</Warning>
              <Level>{badge?.label}</Level>
            </BadgeContent>
          }
          className={null}
          style={null}
        />
      </Flex>
    );
  };

  const renderProgressBar = () => {
    const shouldRenderProgress = progress && secondProgress;
    if (!shouldRenderProgress) {
      return null;
    }

    return (
      <ProgressBar
        progress={`${progress * 10}%`}
        color={red}
        secondProgress={`${secondProgress * 10}%`}
        secondColor={yellow}
        width={500}
        height={15}
      />
    );
  };

  return (
    <Container>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Flex flexDirection={"column"}>
          <Link to={link}>
            <Text as={"span"} fontSize={"18px"} fontWeight={500}>
              {title}
            </Text>
          </Link>
          <Text as={"span"} color={greyFont}>
            {subtitle}
          </Text>
          <Text as={"span"} color={greyFont}>
            {description}
          </Text>
        </Flex>
        {value && <Value>{value}</Value>}
      </Flex>
      <Flex
        mt="15px"
        flex={1}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        {renderProgressBar()}
        {renderBadge()}
      </Flex>
    </Container>
  );
};

export default RiskFindingCard;
