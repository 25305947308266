import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Slider, withStyles } from "@material-ui/core";

import { SliderOptions } from "../../../interfaces/models/FilterInterface";
import { CustomInputInterface } from "./Inputs.interface";

const fatHeight = {
  height: 4,
};

const CustomRange = withStyles({
  root: {
    color: "#007bff",
  },
  track: fatHeight,
  mark: fatHeight,
  rail: fatHeight,
})(Slider);

function RangeInput({
  id,
  options,
  setValue,
  value,
}: CustomInputInterface<number[]> & {
  id: string;
  options: SliderOptions;
}) {
  const [isDisabled, setDisabled] = useState(true);
  const [storedValue, setStoredValue] = useState<number[]>();

  const toggleDisabled = () => {
    if (!isDisabled) {
      setStoredValue(value);
      setDisabled(true);
      setValue(undefined);
      return;
    }
    setDisabled(false);
    setValue(storedValue ?? options.defaultValue);
  };

  const disabler = useMemo(
    () => (
      <Form.Group>
        <Form.Check
          type="checkbox"
          label="Não usar campo"
          id={`disabler-${id}`}
          checked={isDisabled}
          onChange={toggleDisabled}
          className="mt-2"
        />
      </Form.Group>
    ),
    [isDisabled, id]
  );

  return (
    <>
      <div className="d-flex align-items-center">
        {
          <p className="m-0 text-primary h7" style={{ width: "5ch" }}>
            {value?.[0] ?? options.min}
          </p>
        }
        <CustomRange
          {...options}
          aria-labelledby={id}
          value={value}
          onChange={(_e, val) => setValue(val as number[])}
          disabled={isDisabled}
          valueLabelDisplay="off"
          marks
          color={"primary"}
        />
        {
          <p
            className="m-0 text-primary h7 text-right"
            style={{ width: "5ch" }}
          >
            {value?.[1] ?? options.max}
          </p>
        }
      </div>
      {disabler}
    </>
  );
}

export default RangeInput;
