import React, { useState } from "react";
import { FilterGroup } from "../../interfaces/models/FilterInterface";
import Input from "../../components/molecules/FormItems/Input";

import { Flex, Text } from "rebass";

import { Alert, Card, Col, Row } from "react-bootstrap";
const FilterFormRowFormik = ({
  filter: { title, inputs },
  index,
}: {
  filter: FilterGroup;
  index: number;
}) => {
  const [filterDescription, setFilterDescription] = useState<string | null>("");

  const renderFilterDescription = () => {
    if (!filterDescription) {
      return null;
    }

    return (
      <Flex mt={"5px"} flexDirection={"column"} flex={1}>
        <Alert variant={"info"}>
            <Text fontSize={'16px'} fontWeight={500}>{filterDescription}</Text>
        </Alert>
      </Flex>
    );
  };

  return (
    <Card className="border-top-0 border-right-0 border-left-0 border-bottom">
      <Card.Header
        className={
          "bg-white h5 font-weight-bold pl-0 pt-4 d-flex w-100 justify-content-between"
        }
      >
        <Flex flexDirection={"column"} flex={1}>
          <span>{title}</span>
          {renderFilterDescription()}
        </Flex>
      </Card.Header>
      <Card.Body className="px-0">
        <Row>
          {inputs.map((input) => (
            <Col lg={6} key={input.id} className="mb-3 mb-lg-0">
              <Input {...input} setFilterDescription={setFilterDescription} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FilterFormRowFormik;
