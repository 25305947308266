import { useCallback, useEffect, useRef, useState } from "react";

export function useFullScreen(): [boolean, () => void] {
  const [isFullScreen, setFullScreen] = useState(false);
  const fullScreenRef = useRef(isFullScreen);

  const closeFullScreen = () => {
    if (fullScreenRef.current) {
      fullScreenRef.current = false;
      setFullScreen(false);
    }
  };

  const toggleFullScreen = useCallback(() => {
    fullScreenRef.current = !isFullScreen;
    setFullScreen(!isFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    const keydown = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        closeFullScreen();
      }
    };
    window.addEventListener("keydown", keydown);
    return () => window.removeEventListener("keydown", keydown);
  }, []);

  return [isFullScreen, toggleFullScreen];
}
