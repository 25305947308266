import React, { PropsWithChildren } from "react";
import { Redirect, useHistory } from "react-router-dom";

import useConnectedSelector from "../../../utils/useConnectedSelector";

function AuthContainer({ children }: PropsWithChildren<any>) {
	const loginState = useConnectedSelector(
		({ LoginReducer }) => LoginReducer.data
	);

	const { location } = useHistory();

	return loginState?.auth ? (
		children
	) : (
		<Redirect
			to={{
				pathname: "/",
				state: { from: location },
			}}
		/>
	);
}

export default AuthContainer;
