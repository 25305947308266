import React, { useRef } from "react";
import { IFrameInterface } from "../../../interfaces/models/CompanyDetail";
import { Container } from "react-bootstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import styled from "styled-components";
import FullScreenButton from "../../atoms/FullScreenButton/FullScreenButton";
import { useFullScreen } from "./Bock.utils";
import cls from "classnames";

const IFrame = styled.iframe`
  border-color: transparent;
`;

type IFrameBlockProps = {
  iframe: IFrameInterface | any;
};

const IFrameBlock = ({ iframe }: IFrameBlockProps) => {
  const ref = useRef();
  const [isFullScreen, toggleFullScreen] = useFullScreen();

  const className = cls("p-4 bg-white print-no-padding", {
    "position-fixed fixed-full top-70 fixed-front overflow-auto": isFullScreen,
    "position-relative mt-4": !isFullScreen,
  });

  const { titulo, url, height = null } = iframe;

  return (
    <Container fluid className={className} key={titulo}>
      <h4 className={"h5 font-weight-bold mb-4"}>{titulo}</h4>
      <FullScreenButton active={isFullScreen} onClick={toggleFullScreen} />

      <div
        ref={ref}
        onMouseOver={() => disableBodyScroll(ref)}
        onMouseLeave={() => enableBodyScroll(ref)}
      >
        <IFrame
          src={url}
          title={titulo}
          width={"100%"}
          height={height || "512px"}
        />
      </div>
    </Container>
  );
};

export default IFrameBlock;
