import React from "react";
import { CommonTable } from "../../../interfaces/APIInterface";
import { FilterResultRow } from "../../../interfaces/models/FilterResultResponse";
import MaterialTable from "../../../components/atoms/MaterialTable/MaterialTable";
import { Col, Container, Row } from "react-bootstrap";

export type BiddingFilterResultObject = {
  resultados: string[][];
  lotes: CommonTable<FilterResultRow>;
};

export type BiddingFilterResultResponse = {
  result: BiddingFilterResultObject;
};

const BiddingFilterResult = (props) => {
  const { result } = props;
  const {
    resultados,
    lotes: { colunas, linhas, searchable },
  } = result;

  return (
    <>
      <Container
        fluid
        className="bg-white mt-4 py-4 px-5 rounded position-relative sidebar-gradient"
      >
        <h2 className="h3 font-weight-bold mb-4">Resultados</h2>
        <Row style={{ wordBreak: "break-word" }}>
          <Col md={resultados[1] ? 6 : 12}>
            {resultados[0].map ? (
              <ul className="list">
                {resultados[0]?.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{resultados[0]}</p>
            )}
          </Col>
          {resultados[1] && (
            <Col md={6}>
              {resultados[1].map ? (
                <ul className="list">
                  {resultados[1]?.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              ) : (
                <p>{resultados[1]}</p>
              )}
            </Col>
          )}
        </Row>
      </Container>
      <Container fluid className="bg-white mt-4 p-4 rounded">
        <MaterialTable
          columnWithOptions={colunas}
          data={linhas}
          noShadow
          sticky
          searchLeft={searchable !== false}
        />
      </Container>
    </>
  );
};

export default BiddingFilterResult;
