import { Checkbox } from "@material-ui/core";
import currency from "currency.js";
import React from "react";
import { Link } from "react-router-dom";
import { date, percent } from "./formatter";

export default function formatValue(value: string, type?: string) {
  if (!type) return value;

  function renderLink(value: string) {
    return <Link to={value}>{value}</Link>;
  }

  function renderExternalLink(value: string) {
    return (
      <a href={value} target={"_blank"}>
        {value}
      </a>
    );
  }

  function renderMoney(value: string) {
    return currency(value, {
      separator: ".",
      decimal: ",",
      symbol: "R$ ",
    }).format();
  }

  function renderBool(value: string) {
    return <Checkbox value={value.toLocaleLowerCase() === "true"} />;
  }

  function renderDate(value: string) {
    return date(Number.parseInt(value));
  }

  function renderPercent(value: string) {
    return percent(Number.parseFloat(value));
  }

  switch (type) {
    case "money":
      return renderMoney(value);
    case "link":
      return renderLink(value);
    case "bool":
      return renderBool(value);
    case "external-link":
      return renderExternalLink(value);
    case "date":
      return renderDate(value);
    case "percent":
      return renderPercent(value);
    default:
      return undefined;
  }
}
