import React, { useMemo } from "react";

import { PoliticianDetailResponse } from "../../../interfaces/models/PoliticianDetail";
import WarningHeader from "../../molecules/WarningHeader";
import ContentOrderer from "../../organisms/ContentOrderer/ContentOrderer";

function PoliticianDetailTemplate({
	politician: { nome, tabelas, cabecalho },
}: {
	politician: PoliticianDetailResponse;
}) {
	const renderedElements = useMemo(
		() =>
			ContentOrderer({
				tables: tabelas,
			}),
		[tabelas]
	);
	return (
		<div>
			<h3 className="h4 font-weight-bold">{nome}</h3>
			<WarningHeader content={cabecalho} />
			{renderedElements}
		</div>
	);
}

export default PoliticianDetailTemplate;
